/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../App';
import Axios from 'axios';
import { notification, Layout, Button, Input, Popconfirm, Drawer, Empty, Table, Upload, Row, Col, Divider } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { ReactComponent as Magnifying } from '../../icons/magnifying-glas.svg';
import { ReactComponent as Trash } from '../../icons/ph_trash.svg';
import { ReactComponent as Edit } from '../../icons/ph_note-pencil-light.svg';
import { ReactComponent as Create } from '../../icons/Create.svg';
import { SERVER_URL } from '../../config';
import HeaderComponent from '../../components/base/HeaderComponent';
import AIQbuilderForm from '../../components/forms/AIQbuilderForm';

const AIQBuilder = () => {
  const currentuser = useContext(UserContext);
  const [builders, setBuilders] = useState();
  const [builderForEdit, setBuilderForEdit] = useState(null);
  const [newBuilder, setNewBuilder] = useState(null);
  const [createAIQ, setCreateAIQ] = useState(null);
  const [qoolections, setQoolections] = useState([]);
  const [stopWord, setStopWord] = useState(null);
  const [qoolectionName, setQoolectionName] = useState(null);

  const getBuilders = async (search = null) => {
    const response =
      search && search.length > 1
        ? await Axios.get(
            `${SERVER_URL}/ai-qbuilder?filter=${JSON.stringify({ title: { $regex: search, $options: 'i' } })}`,
            {
              withCredentials: false,
              headers: { Authorization: `Bearer ${currentuser.data.token}` },
            },
          )
        : await Axios.get(`${SERVER_URL}/ai-qbuilder`, {
            withCredentials: false,
            headers: { Authorization: `Bearer ${currentuser.data.token}` },
          });
    response?.data?.items ? setBuilders(response.data.items) : setBuilders([]);
  };

  const getBuilderForEdit = async (id) => {
    const response = await Axios.get(`${SERVER_URL}/ai-qbuilder/${id}`, {
      withCredentials: false,
      headers: { Authorization: `Bearer ${currentuser.data.token}` },
    });
    response?.data ? setBuilderForEdit(response.data) : setBuilderForEdit(null);
  };

  const getQoolectionsForAIQBuilder = async () => {
    try {
      const response = await Axios.get(`${SERVER_URL}/qoolections-for-ai-qbuilder`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      if (response?.data?.items) {
        setQoolections(response?.data?.items);
      } else setQoolections([]);
    } catch (error) {
      console.log('Error getting qoolections:', error?.response?.data?.message);
    }
  };

  useEffect(() => {
    getBuilders();
    getQoolectionsForAIQBuilder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setBuilders, setBuilderForEdit, setNewBuilder]);

  const deleteBuilderHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/ai-qbuilder/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({ message: 'Builder deleted successfully', placement: 'topLeft', duration: 2 });
      getBuilders();
    } catch (error) {
      console.log(error);
      notification.error({
        message: error?.response?.data?.message || 'Problem with delete.',
        placement: 'topLeft',
        duration: 2,
      });
    }
  };

  const addAndEditBuilderHandler = async (formData, isNew) => {
    const method = isNew ? 'post' : 'put';
    const route = isNew ? `${SERVER_URL}/ai-qbuilder` : `${SERVER_URL}/ai-qbuilder/${builderForEdit._id}`;
    try {
      await Axios[method](route, formData, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: `Builder ${isNew ? 'added' : 'updated'} successfully`,
        placement: 'topLeft',
        duration: 2,
      });
      setBuilderForEdit(null);
      setNewBuilder(null);
      getBuilders();
    } catch (error) {
      console.log(error);
      notification.error({
        message: error?.response?.data?.message || 'Problem with create/edit.',
        placement: 'topLeft',
        duration: 2,
      });
    }
  };

  const handleSearch = (e) => getBuilders(e);

  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
    },
    {
      title: 'Qoolection',
      dataIndex: ['qoolection', 'title'],
    },
    {
      title: 'Temperature',
      dataIndex: 'temperature',
    },
    {
      title: 'Max Tokens',
      dataIndex: 'max_tokens',
    },
    {
      title: 'Action',
      render: (text, record) => (
        <div style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center', fontSize: '15px' }}>
          <div style={{ margin: '2px', padding: '2px', cursor: 'pointer' }}>
            <Popconfirm
              placement='left'
              title={`This will delete study`}
              onConfirm={() => deleteBuilderHandler(record._id)}
              okText='Ok'
              cancelText='Cancel'
            >
              <Trash title={`Delete Builder`} style={{ color: '#90989E' }} />
            </Popconfirm>
          </div>
          <div style={{ margin: '2px', padding: '2px', cursor: 'pointer' }}>
            <Edit
              onClick={() => getBuilderForEdit(record._id)}
              title={`Edit Builder`}
              style={{ textDecoration: 'none', color: '#90989E' }}
            />
          </div>
          <div style={{ margin: '2px', padding: '2px', cursor: 'pointer' }}>
            <Create
              onClick={() => setCreateAIQ(record._id)}
              title={`Create AIQ`}
              style={{ textDecoration: 'none', color: '#90989E' }}
            />
          </div>
        </div>
      ),
    },
  ];

  return (
    <Layout className='manage-layout'>
      <div
        style={{ textAlign: 'center', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}
      >
        <HeaderComponent text='Manage Builders' />
        <div className='teams-content'>
          <div className='teams-container'>
            <div className='teams-upper-part'>
              <div className='left-part'>
                <Input
                  className='inppy'
                  placeholder='Search'
                  onChange={(e) => handleSearch(e.target.value)}
                  prefix={<Magnifying style={{ marginRight: '5px' }} />}
                />
                <Button className='butty' type='primary' onClick={() => setNewBuilder(true)}>
                  Add new Builder
                </Button>
              </div>
            </div>

            <div className='table-manage'>
              <Table
                size='middle'
                bordered
                dataSource={builders}
                columns={columns}
                locale={{ emptyText: <Empty description='No Builders, please add Builder.' /> }}
                rowKey='_id'
                pagination={{
                  defaultPageSize: 20,
                  position: 'bottom',
                  showSizeChanger: false,
                  pageSizeOptions: ['10', '20', '50', '100'],
                  hideOnSinglePage: false,
                }}
              />
            </div>
          </div>
        </div>
        {builderForEdit && (
          <div>
            <Drawer open={builderForEdit} onClose={() => setBuilderForEdit(null)} width='100VW'>
              <div style={{ padding: '8px' }}>
                <div>
                  <AIQbuilderForm
                    isNew={false}
                    data={builderForEdit}
                    qoolections={qoolections}
                    onSubmit={addAndEditBuilderHandler}
                    SERVER_URL={SERVER_URL}
                  />
                </div>
              </div>
            </Drawer>
          </div>
        )}
        {newBuilder && (
          <div>
            <Drawer open={newBuilder} onClose={() => setNewBuilder(null)} width='100VW'>
              <div style={{ padding: '10px 0px 40px 0px' }}>
                <div>
                  <AIQbuilderForm
                    isNew={true}
                    data={builderForEdit}
                    qoolections={qoolections}
                    onSubmit={addAndEditBuilderHandler}
                    SERVER_URL={SERVER_URL}
                  />
                </div>
              </div>
            </Drawer>
          </div>
        )}
        {createAIQ && (
          <div>
            <Drawer open={createAIQ} onClose={() => setCreateAIQ(null)} width='50VW'>
              <div style={{ padding: '10px 0px 40px 0px' }}>
                <Row gutter={4}>
                  <Col span={24}>
                    New Qoolection Name. Required*
                    <Input
                      placeholder='Enter name for new qoolection'
                      value={qoolectionName}
                      onChange={(e) => setQoolectionName(e.target.value)}
                    />
                  </Col>
                  <Divider />
                  <Col span={24}>
                    Add stop word to exclude from pdf. Optional.
                    <Input
                      placeholder='Exclude everything after specific text in PDF. Example Collaboration Authors'
                      value={stopWord}
                      onChange={(e) => setStopWord(e.target.value)}
                    />
                  </Col>
                  <Divider />
                  <Col span={4}>
                    <Upload
                      disabled={qoolectionName === null}
                      tyle={{ padding: '10px 0px 40px 0px' }}
                      action={`${SERVER_URL}/generate-aiq`}
                      accept='.pdf'
                      name='ai-qbuilder'
                      headers={{
                        fileName: 'file',
                        Authorization: currentuser.data.token,
                        builderId: createAIQ,
                        stopWord: stopWord,
                        qoolectionName: qoolectionName,
                      }}
                      fileList={[]}
                    >
                      <Button
                        title='Upload PDF file'
                        style={{ marginLeft: '1rem' }}
                        type='secondary'
                        icon={<UploadOutlined />}
                      >
                        Upload PDF
                      </Button>
                    </Upload>
                  </Col>
                </Row>
              </div>
            </Drawer>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default AIQBuilder;

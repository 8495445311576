import React, { useState, useEffect } from 'react';
import readXlsxFile from 'read-excel-file';
import Fuse from 'fuse.js';
import { Button, Popconfirm, Table, Modal, Tag, Divider, Empty, Checkbox, Tabs, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import StepNavigation from './StepNavigation';
import ClientUserForm from '../../components/forms/ClientUserForm';
import { ReactComponent as Trash } from '../../icons/ph_trash.svg';
import { ReactComponent as Edit } from '../../icons/ph_note-pencil-light.svg';

const groupUsersByTeam = (array) =>
  Object.values(
    array.reduce((a, item) => {
      const { email, team } = item;
      if (!a[email]) a[email] = item;
      if (a[email].team.filter((t) => t._id === team[0]._id).length === 0) a[email].team.push(team[0]);
      return a;
    }, {}),
  );

const SettingsStepUsers = ({
  step,
  setStep,
  completedStep,
  setCompletedStep,
  addAndEditClientUserHandler,
  getUserForEdit,
  userForEdit,
  setUserForEdit,
  deleteClientUserHandler,
  teams,
  branches,
  client,
  users,
  bulkAddUsersHandler,
  downloadUserTemplateHandler,
}) => {
  const [bulkUploadData, setBulkUploadData] = useState([]);
  const [bulkUploadUserEmails, setBulkUploadUserEmails] = useState([]);
  const [uniqueUserEmails, setUniqueUserEmails] = useState([]);
  const [availableCreatorLicences, setAvailableCreatorLicences] = useState(0);
  const [availableMonitorLicences, setAvailableMonitorLicences] = useState(0);
  const [requiredCreatorLicences, setRequiredCreatorLicences] = useState(0);
  const [requiredMonitorLicences, setRequiredMonitorLicences] = useState(0);

  useEffect(() => {
    setAvailableCreatorLicences(
      (client?.totalCreatorLicences ? client.totalCreatorLicences : 0) -
        (client?.licencedCreators ? client.licencedCreators?.length : 0),
    );
    setAvailableMonitorLicences(
      (client?.totalMonitorLicences ? client.totalMonitorLicences : 0) -
        (client?.licencedMonitors ? client.licencedMonitors.length : 0),
    );
    setUniqueUserEmails(users.map((u) => u.email));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setBulkUploadData, setAvailableCreatorLicences, setAvailableMonitorLicences, setUniqueUserEmails, users, client]);

  const searchTeams = (term) => {
    const options = {
      includeScore: true,
      threshold: 0.5,
      keys: ['name'],
    };
    const fuse = new Fuse(teams, options);
    const found = fuse.search(term);
    return [found[0]?.item];
  };

  const searchBranches = (term) => {
    const options = {
      includeScore: true,
      threshold: 0.5,
      keys: ['branchName'],
    };
    const fuse = new Fuse(branches, options);
    const found = fuse.search(term);
    return found[0]?.item;
  };

  const uploadData = (file) => {
    let newBulkUploadData = [];
    return new Promise(async (resolve) => {
      await readXlsxFile(file, { sheet: 2 }).then(async (rows) => {
        // eslint-disable-next-line array-callback-return
        const promises = rows.slice(1).map((r, index) => {
          if (r[0] && r[1] && r[2]) {
            return newBulkUploadData.push({
              key: index,
              email: r[1].trim(),
              firstName: r[2].trim(),
              lastName: r[3].trim(),
              role: [r[4].trim()],
              team: searchTeams(r[5].trim()),
              jobFunction: r[6].trim(),
              clientBranch: searchBranches(r[7].trim()),
              licenceActive: r[8].trim() === 'Yes' ? true : false,
              client: client?._id,
            });
          }
        });
        await Promise.all(promises);
      });
      const group = groupUsersByTeam(newBulkUploadData);
      // Get number of creator licences required
      setRequiredCreatorLicences(group.filter((g) => g.role[0] === 'creator' || g.role[0] === 'agency').length);
      setRequiredMonitorLicences(group.filter((g) => g.role[0] === 'monitor').length);
      setBulkUploadUserEmails(group.map((g) => g.email));
      resolve(setBulkUploadData(group));
    });
  };

  const columns = [
    {
      title: 'First Name',
      dataIndex: 'firstName',
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
    },
    {
      title: 'Department',
      dataIndex: 'jobFunction',
    },
    {
      title: 'Role',
      dataIndex: 'role',
      render: (text, record) =>
        record.role.map((role) => (
          <Tag style={{ backgroundColor: '#b3f5e1' }} key={role}>
            {role}
          </Tag>
        )),
    },
    {
      title: 'Team',
      dataIndex: 'team',
      render: (text, record) =>
        record.team.map((team) => (
          <Tag style={{ backgroundColor: '#e483f7' }} key={team._id}>
            {team.name}
          </Tag>
        )),
    },
    {
      title: 'Branch',
      dataIndex: ['clientBranch', 'branchName'],
    },
    {
      title: 'License',
      dataIndex: 'licenceActive',
      render: (text, record) => <Checkbox disabled checked={record.licenceActive ? true : false} />,
    },
    {
      title: 'Action',
      render: (text, record) => (
        <div style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center', fontSize: '15px' }}>
          <div style={{ margin: '2px', padding: '2px', cursor: 'pointer' }}>
            <Popconfirm
              placement='left'
              title={`This will delete user`}
              onConfirm={() => deleteClientUserHandler(record._id)}
              okText='Ok'
              cancelText='Cancel'
            >
              <Trash title={`Delete user`} />
            </Popconfirm>
          </div>
          <div style={{ margin: '2px', padding: '2px', cursor: 'pointer' }}>
            <Edit
              onClick={() => getUserForEdit(record._id)}
              title={`Edit user`}
              style={{ textDecoration: 'none', color: 'black' }}
            />
          </div>
        </div>
      ),
      fixed: 'right',
    },
  ];

  const columnsBulkUpload = [
    {
      title: 'First Name',
      dataIndex: 'firstName',
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
    },
    {
      title: 'Email',
      dataIndex: 'email',
    },
    {
      title: 'Department',
      dataIndex: 'jobFunction',
    },
    {
      title: 'Role',
      dataIndex: 'role',
      render: (text, record) =>
        record.role.map((r, index) => (
          <Tag style={{ backgroundColor: '#b3f5e1' }} key={index + 1000}>
            {r}
          </Tag>
        )),
    },
    {
      title: 'Team',
      dataIndex: 'team',
      render: (text, record) =>
        record.team.map((t) => (
          <Tag style={{ backgroundColor: '#e483f7' }} key={t._id}>
            {t.name}
          </Tag>
        )),
    },
    {
      title: 'Branch',
      dataIndex: ['clientBranch', 'branchName'],
    },
    {
      title: 'License',
      dataIndex: 'licenceActive',
      fixed: 'right',
      width: 60,
      align: 'center',
      render: (text, record) => <Checkbox defaultChecked={record.licenceActive} disabled />,
    },
  ];

  return (
    <div className='company-settings-builder__step-1'>
      <div className='company-settings-builder__left-side'>
        <h2 className='company-settings-builder__step-subtitle'>
          <span>Step 4: </span>
          <span>Add Users.</span>
        </h2>
        <StepNavigation step={step} setStep={setStep} completedStep={completedStep} />
        <p className='company-settings-builder__intro-text'>
          Next, add Users that will be using the QClinical app, and assign them roles and licenses. As with the Studies
          and Teams, you can choose to add the Users manually, or you can use our Bulk upload function by downloading
          and completing our template.
        </p>
        <div className='company-settings-builder__forms'>
          <Tabs defaultActiveKey='1' centered className='company-settings__tabs'>
            <Tabs.TabPane tab='Manual upload' key='1'>
              {teams && teams.length > 0 && (
                <ClientUserForm
                  isNew={true}
                  teams={teams}
                  branchs={branches}
                  createHandler={addAndEditClientUserHandler}
                  updateHandler={addAndEditClientUserHandler}
                  createProcess={true}
                  availableCreatorLicences={availableCreatorLicences}
                  availableMonitorLicences={availableMonitorLicences}
                />
              )}
            </Tabs.TabPane>

            <Tabs.TabPane tab='Bulk upload' key='2'>
              <p className='company-settings-builder__intro-text'>
                Please download our preformatted template for uploading your User information here. There are additional
                guidelines for using the template included on the first tab. Once you have filled out the template,
                please return{' '}
                <span style={{ color: 'blue', cursor: 'pointer' }} onClick={downloadUserTemplateHandler}>
                  here
                </span>{' '}
                to upload the file.
              </p>
              <div className='bulk-upload-wrapper'>
                <Upload
                  className='bulk-upload-teams'
                  style={{ margin: 0 }}
                  action={uploadData}
                  accept='.xlsx'
                  name='excelData'
                >
                  <Button style={{ margin: 0 }} type='primary'>
                    {bulkUploadData && bulkUploadData.length === 0 ? 'Upload your completed template' : 'Re-upload'}
                  </Button>
                </Upload>
              </div>
              {/* <Table
                size='middle'
                bordered
                dataSource={bulkUploadData}
                columns={columnsBulkUpload}
                rowKey='key'
                locale={{
                  emptyText: (
                    <Empty
                      style={{ fontWeight: 800, fontSize: 24 }}
                      description='Nothing to add, please upload excel user template.'
                    />
                  ),
                }}
                rowClassName={(record) => (uniqueUserEmails.includes(record.email) ? 'email-exists-warning' : '')}
                scroll={{ x: 700 }}
                pagination={{
                  defaultPageSize: 5,
                  position: 'bottom',
                  showSizeChanger: true,
                  pageSizeOptions: [5, 10, 20],
                  hideOnSinglePage: false,
                }}
              /> */}
              {availableCreatorLicences < requiredCreatorLicences ||
              availableMonitorLicences < requiredMonitorLicences ? (
                <div className='company-settings-builder__licence-alert'>
                  Remaining Creator licenses: {availableCreatorLicences} <br />
                  Required Creator licenses: {requiredCreatorLicences} <br />
                  Available Monitor licenses: {availableMonitorLicences} <br />
                  Required Monitor licenses: {requiredMonitorLicences} <br />
                  Not enough available licenses. &#9888;
                  <br />
                  Please contact your account manager to purchase more licenses.
                </div>
              ) : (
                <div className='company-settings-builder__licence-ok'>You have enough available licenses &#x2713;</div>
              )}
              {bulkUploadUserEmails.some((email) => uniqueUserEmails.includes(email)) ? (
                <div className='company-settings-builder__licence-alert'>
                  At least one user email is already in the list. Please remove it and try again. &#9888;
                </div>
              ) : (
                <div className='company-settings-builder__licence-ok'>All emails are unique &#x2713;</div>
              )}
              {bulkUploadData && bulkUploadData.length > 0 && (
                <Button
                  className='bulk-upload-save-button'
                  disabled={
                    availableCreatorLicences < requiredCreatorLicences ||
                    availableMonitorLicences < requiredMonitorLicences ||
                    bulkUploadUserEmails.some((email) => uniqueUserEmails.includes(email))
                  }
                  onClick={() => bulkAddUsersHandler(bulkUploadData)}
                >
                  Add Users
                </Button>
              )}
            </Tabs.TabPane>
          </Tabs>
        </div>
        <div className='company-settings-builder__bottom-row'>
          <Button className='company-settings-builder__step-btn' onClick={() => setStep(3)}>
            Back
          </Button>
          <Button
            id={'company-settings_next-step'}
            className='company-settings-builder__step-btn'
            type='primary'
            disabled={users.length === 0}
            onClick={() => {
              if (completedStep === 3) setCompletedStep(4);
              setStep(5);
            }}
          >
            Next
          </Button>
        </div>
      </div>

      <Divider type='vertical' style={{ height: 'auto' }} />

      <div className='table-manage'>
        <Table
          size='middle'
          bordered
          dataSource={users}
          columns={columns}
          locale={{ emptyText: <Empty description='No Teams, please add a team.' /> }}
          rowKey='_id'
          pagination={{
            defaultPageSize: 6,
            position: 'bottom',
            showSizeChanger: false,
            pageSizeOptions: ['10', '20', '50', '100'],
            hideOnSinglePage: false,
          }}
        />
      </div>

      {userForEdit && userForEdit !== null && teams && teams.length > 0 && (
        <div>
          <Modal open={userForEdit} onCancel={() => setUserForEdit(null)} footer={null} centered={true}>
            <div style={{ padding: '8px' }}>
              <div>
                <ClientUserForm
                  isNew={false}
                  data={userForEdit}
                  branchs={branches}
                  teams={teams}
                  createHandler={addAndEditClientUserHandler}
                  updateHandler={addAndEditClientUserHandler}
                  availableCreatorLicences={availableCreatorLicences}
                  availableMonitorLicences={availableMonitorLicences}
                />
              </div>
            </div>
          </Modal>
        </div>
      )}
    </div>
  );
};

export default SettingsStepUsers;

import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const ProtectedRoute = ({ component: Component, allowed, user, ...rest }) => {

  return (
    <Route
      {...rest}
      render={(props) =>
        user && allowed.some((role) => user.role.includes(role)) ? ( // if signed in and user's role is allowed
          <Component {...props} /> // render component
        ) : (
          <Redirect to='/login' /> // else redirect
        )
      }
    />
  );
};

export default ProtectedRoute;

import React, { useState, useEffect } from 'react';
import readXlsxFile from 'read-excel-file';
import { UploadOutlined } from '@ant-design/icons';
import Axios from 'axios';
import { Link } from 'react-router-dom';
import { Button, Popconfirm, Table, Modal, Divider, Empty, Tabs, Tag, Upload } from 'antd';
import StepNavigation from './StepNavigation';
import PersonnelForm from '../../components/forms/PersonnelForm';
import { ReactComponent as Trash } from '../../icons/ph_trash.svg';
import { ReactComponent as Edit } from '../../icons/ph_note-pencil-light.svg';
import { SERVER_URL } from '../../config';
import { useContext } from 'react';
import { UserContext } from '../../App';

const SettingsStepPersonnels = ({
  step,
  setStep,
  completedStep,
  branches,
  setCompletedStep,
  addAndEditPersonnelHandler,
  getPersonnelForEdit,
  personnelForEdit,
  setPersonnelForEdit,
  deletePersonnelHandler,
  personnels,
  bulkAddPersonnelsHandler,
  downloadPersonnelsTemplateHandler,
  qoolections = [],
  client,
}) => {
  const currentuser = useContext(UserContext);
  const [bulkUploadData, setBulkUploadData] = useState([]);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setBulkUploadData, bulkUploadData]);

  const uploadData = (file) => {
    let newBulkUploadData = [];
    return new Promise(async (resolve) => {
      await readXlsxFile(file, { sheet: 2 }).then(async (rows) => {
        // eslint-disable-next-line array-callback-return
        const promises = rows.slice(1).map((r, index) => {
          if (r[0] && r[1] && r[2] && r[3] && r[4] && r[5] && r[6]) {
            return newBulkUploadData.push({
              key: index,
              deviceId: (client?.companyPrefix + r[1]).toUpperCase(),
              firstName: r[2].trim(),
              lastName: r[3].trim(),
              email: r[4].trim(),
              phone: r[5],
              site: r[6],
            });
          }
        });
        await Promise.all(promises);
      });
      console.log(newBulkUploadData);
      resolve(setBulkUploadData(newBulkUploadData));
    });
  };

  const columns = [
    {
      title: 'Device ID',
      dataIndex: 'deviceId',
    },
    {
      title: 'First Name',
      dataIndex: 'firstName',
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
    },
    {
      title: 'Email',
      dataIndex: 'email',
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
    },
    {
      title: 'Site',
      dataIndex: 'site',
      render: (text, record) => (
        <Tag style={{ backgroundColor: '#E5f9c6' }} key={record?.site?._id + 110}>
          {record?.site?.branchName}
        </Tag>
      ),
    },
    {
      title: 'Action',
      render: (text, record) => (
        <div style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center', fontSize: '15px' }}>
          <div style={{ margin: '2px', padding: '2px', cursor: 'pointer' }}>
            <Popconfirm
              placement='left'
              title={`This will delete personnel`}
              onConfirm={() => deletePersonnelHandler(record._id)}
              okText='Ok'
              cancelText='Cancel'
            >
              <Trash title={`Delete personnel`} />
            </Popconfirm>
          </div>
          <div style={{ margin: '2px', padding: '2px', cursor: 'pointer' }}>
            <Edit
              onClick={() => getPersonnelForEdit(record._id)}
              title={`Edit personnel`}
              style={{ textDecoration: 'none', color: 'black' }}
            />
          </div>
        </div>
      ),
      fixed: 'right',
    },
  ];

  const columnsBulkUpload = [
    {
      title: 'User ID',
      dataIndex: 'deviceId',
    },
    {
      title: 'First Name',
      dataIndex: 'firstName',
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
    },
    {
      title: 'Email',
      dataIndex: 'email',
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
    },
    {
      title: 'Site',
      dataIndex: 'site',
    },
  ];

  const finishWizard = async () => {
    try {
      await Axios.put(
        `${SERVER_URL}/clients/${currentuser?.data?.client?._id}`,
        {
          ...currentuser?.data?.client,
          setupFinished: true,
        },
        { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` } },
      );
      sessionStorage.setItem('setupWizardFinished', true);
      window.location.href = '/';
    } catch (error) {
      throw new Error(error);
    }
  };

  return (
    <div className='company-settings-builder__step-1'>
      <div className='company-settings-builder__left-side'>
        <h2 className='company-settings-builder__step-subtitle'>
          <span>Step 6: </span>
          <span>Add Site Personnel (Optional).</span>
        </h2>
        <StepNavigation step={step} setStep={setStep} completedStep={completedStep} />
        <p className='company-settings-builder__intro-text'>
          Add Site Personnel to whom you will be sending published Qoolections. The table on the right will populate as
          you keep adding Site Personnel. As with the previous steps, you can choose to add the Site Personnel manually,
          or you can use our Bulk Upload function by downloading and completing our template.
        </p>
        <div className='company-settings-builder__forms'>
          <Tabs defaultActiveKey='1' centered className='company-settings__tabs'>
            <Tabs.TabPane tab='Manual Upload' key='1'>
              <PersonnelForm
                isNew={true}
                isWizard={true}
                onSubmit={addAndEditPersonnelHandler}
                branches={branches}
                qoolections={qoolections}
                createProcess={true}
                companyPrefix={client?.companyPrefix}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab='Bulk Upload' key='2'>
              <p className='company-settings-builder__intro-text'>
                You can download bulk upload template from{' '}
                <span style={{ color: 'blue', cursor: 'pointer' }} onClick={downloadPersonnelsTemplateHandler}>
                  here.
                </span>
              </p>
              <div className='bulk-upload-wrapper'>
                <Upload
                  className='bulk-upload-teams'
                  action={uploadData}
                  accept='.xlsx'
                  name='excelData'
                  style={{ margin: 0 }}
                >
                  <Button style={{ margin: 0 }} type='primary'>
                    {bulkUploadData && bulkUploadData.length === 0 ? 'Upload your complete template' : 'Re-upload'}
                  </Button>
                </Upload>
              </div>
              {/* <Table
                size='middle'
                bordered
                dataSource={bulkUploadData}
                columns={columnsBulkUpload}
                rowKey='key'
                pagination={{
                  defaultPageSize: 10,
                  position: 'bottom',
                  showSizeChanger: false,
                  pageSizeOptions: ['10', '20', '50', '100'],
                  hideOnSinglePage: false,
                }}
              /> */}
              {bulkUploadData && bulkUploadData.length > 0 && (
                <Button className='bulk-upload-save-button' onClick={() => bulkAddPersonnelsHandler(bulkUploadData)}>
                  Add/Update Personnels
                </Button>
              )}
            </Tabs.TabPane>
          </Tabs>
        </div>
        <div className='company-settings-builder__bottom-row'>
          <Button className='company-settings-builder__step-btn' onClick={() => setStep(5)}>
            Back
          </Button>
          {/* <Link to='/home'> */}
          <Button
            id={'company-settings_finish'}
            className='company-settings-builder__step-btn'
            type='primary'
            // disabled={personnels.length === 0}
            onClick={() => {
              finishWizard();
            }}
          >
            Finish
          </Button>
          {/* </Link> */}
        </div>
      </div>

      <Divider type='vertical' style={{ height: 'auto' }} />

      <div className='table-manage'>
        <Table
          size='middle'
          bordered
          dataSource={personnels}
          columns={columns}
          locale={{ emptyText: <Empty description='No Personnel, please add personnel.' /> }}
          rowKey='_id'
          pagination={{
            defaultPageSize: 20,
            position: 'bottom',
            showSizeChanger: false,
            pageSizeOptions: ['10', '20', '50', '100'],
            hideOnSinglePage: false,
          }}
        />
      </div>

      {personnelForEdit && (
        <div>
          <Modal open={personnelForEdit} onCancel={() => setPersonnelForEdit(null)} footer={null} centered={true}>
            <div style={{ padding: '8px' }}>
              <div>
                <PersonnelForm
                  isNew={false}
                  isWizard={true}
                  data={personnelForEdit}
                  onSubmit={addAndEditPersonnelHandler}
                  branches={branches}
                  qoolections={qoolections}
                  companyPrefix={client?.companyPrefix}
                />
              </div>
            </div>
          </Modal>
        </div>
      )}
    </div>
  );
};
export default SettingsStepPersonnels;

import Home from '../pages/Home';
import Admins from '../pages/admins/Admins';
import EditAdmin from '../pages/admins/EditAdmin';
import Users from '../pages/client-users/ClientUsers';
import Clients from '../pages/clients/Clients';
import EditClient from '../pages/clients/EditClient';
import Categories from '../pages/categories/Categories';
import CompanySettings from '../pages/company-settings/CompanySettingsBuilder';
import EditCategory from '../pages/categories/EditCategory';
import QooTypes from '../pages/qooTypes/QooTypes';
import EditQooType from '../pages/qooTypes/EditQooType';
import Studies from '../pages/studies/Studies';
import Patients from '../pages/patients/Patients';
import Personnels from '../pages/personnels/Personnels';
import Teams from '../pages/teams/Teams';
import Audiences from '../pages/audiences/Audiences';
import EditAudience from '../pages/audiences/EditAudience';
import Statuses from '../pages/statuses/Statuses';
import EditStatus from '../pages/statuses/EditStatus';
import TherapeuticArea from '../pages/therapeutic-area/TherapeuticArea';
import EditBlockType from '../pages/blockTypes/EditBlockType';
import AIQBuilder from '../pages/ai-qbuilder/AI-Qbuilder';
import { ReactComponent as Manage } from '../icons/Manage.svg';
import { ReactComponent as Flower2 } from '../icons/ph_flower-lotus-light2.svg';
import { ReactComponent as UserList2 } from '../icons/ph_user-list2.svg';
import { ReactComponent as UserSquare2 } from '../icons/ph_user-square2.svg';
import { ReactComponent as HomeIcon } from '../icons/Home.svg';
import { ReactComponent as Setup } from '../icons/Setup.svg';
import { UserOutlined, SettingOutlined, DownOutlined, TeamOutlined } from '@ant-design/icons';

const routes = [
  {
    label: 'Home',
    icon: HomeIcon,
    path: '/',
    component: Home,
    showInMenu: ['superadmin', 'admin'],
  },
  {
    label: 'Clients',
    icon: UserOutlined,
    showInMenu: ['superadmin'],
    children: [
      {
        label: 'Show all Clients',
        path: '/clients',
        component: Clients,
        allowed: ['superadmin'],
        showInMenu: true,
      },
      {
        label: 'Add new Client',
        path: '/new-client',
        component: EditClient,
        allowed: ['superadmin'],
        showInMenu: true,
      },
      {
        label: 'Edit Client',
        path: '/edit-client/:id',
        component: EditClient,
        allowed: ['superadmin'],
        showInMenu: false,
      },
    ],
  },
  {
    label: 'Admins',
    icon: UserList2,
    showInMenu: ['superadmin'],
    children: [
      {
        label: 'Show all Admins',
        path: '/admins',
        component: Admins,
        allowed: ['superadmin'],
        showInMenu: true,
      },
      {
        label: 'Add new Admin',
        path: '/new-admin',
        component: EditAdmin,
        allowed: ['superadmin'],
        showInMenu: true,
      },
      {
        label: 'Edit Admin',
        path: '/edit-admin/:id',
        component: EditAdmin,
        allowed: ['superadmin'],
        showInMenu: false,
      },
    ],
  },
  {
    label: 'Company Settings',
    icon: Setup,
    path: '/company-settings',
    component: CompanySettings,
    showInMenu: ['admin'],
  },
  {
    label: 'Manage',
    icon: Manage,
    showInMenu: ['admin'],
    children: [
      {
        label: 'Studies',
        icon: <UserList2 />,
        path: '/studies',
        component: Studies,
        allowed: ['admin'],
        showInMenu: true,
      },
      {
        label: 'Patients',
        icon: <Flower2 />,
        path: '/patients',
        component: Patients,
        allowed: ['admin'],
        showInMenu: true,
      },
      {
        label: 'Personnel',
        icon: <TeamOutlined />,
        path: '/personnels',
        component: Personnels,
        allowed: ['admin'],
        showInMenu: true,
      },
      {
        label: 'Teams',
        icon: <UserList2 />,
        path: '/teams',
        component: Teams,
        allowed: ['admin'],
        showInMenu: true,
      },
      {
        label: 'Users',
        icon: <UserSquare2 />,
        path: '/client-users',
        component: Users,
        allowed: ['admin'],
        showInMenu: true,
      },
    ],
  },
  {
    label: 'Block&Qoo Settings',
    icon: Setup,
    showInMenu: ['superadmin'],
    children: [
      {
        label: 'Edit Block Type',
        path: '/edit-block-type/:blockTypeId',
        component: EditBlockType,
        allowed: ['superadmin'],
        showInMenu: false,
      },
      {
        label: 'Show all Qoo Types',
        path: '/qoo-types',
        component: QooTypes,
        allowed: ['superadmin'],
        showInMenu: true,
      },
      {
        label: 'Add new Qoo Type',
        path: '/new-qoo-type',
        component: EditQooType,
        allowed: ['superadmin'],
        showInMenu: true,
      },
      {
        label: 'Edit Qoo Type',
        path: '/edit-Qoo-type/:id',
        component: EditQooType,
        allowed: ['superadmin'],
        showInMenu: false,
      },
    ],
  },
  {
    label: 'General Settings',
    icon: SettingOutlined,
    showInMenu: ['superadmin'],
    children: [
      {
        label: 'Show all Statuses',
        path: '/statuses',
        component: Statuses,
        allowed: ['superadmin'],
        showInMenu: true,
      },
      {
        label: 'Add new Status',
        path: '/new-status',
        component: EditStatus,
        allowed: ['superadmin'],
        showInMenu: true,
      },
      {
        label: 'Edit Status',
        path: '/edit-status/:statusId',
        component: EditStatus,
        allowed: ['superadmin'],
        showInMenu: false,
      },
      {
        label: 'Show all Categories',
        path: '/categories',
        component: Categories,
        allowed: ['superadmin'],
        showInMenu: true,
      },
      {
        label: 'Add new Category',
        path: '/new-category',
        component: EditCategory,
        allowed: ['superadmin'],
        showInMenu: true,
      },
      {
        label: 'Edit Category',
        path: '/edit-category/:categoryId',
        component: EditCategory,
        allowed: ['superadmin'],
        showInMenu: false,
      },
      {
        label: 'Show all Audiences',
        path: '/audiences',
        component: Audiences,
        allowed: ['superadmin'],
        showInMenu: true,
      },
      {
        label: 'Add new Audience',
        path: '/new-audience',
        component: EditAudience,
        allowed: ['superadmin'],
        showInMenu: true,
      },
      {
        label: 'Edit Audience',
        path: '/edit-audience/:audienceId',
        component: EditAudience,
        allowed: ['superadmin'],
        showInMenu: false,
      },
      {
        label: 'Therapeutic Areas',
        path: '/therapeutic-area',
        component: TherapeuticArea,
        allowed: ['superadmin'],
        showInMenu: true,
      },
    ],
  },
  {
    label: 'GPT Settings',
    icon: SettingOutlined,
    showInMenu: ['superadmin'],
    children: [
      {
        label: 'Qoolection Builder',
        path: '/gptqoolection-builder',
        component: AIQBuilder,
        allowed: ['superadmin'],
        showInMenu: true,
      },
    ],
  },
];
export default routes;

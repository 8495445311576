import React from 'react';

const StepNavigation = ({ step, setStep, completedStep }) => {
  return (
    <div className='step-navigation'>
      <div
        className={step === 1 ? 'step-navigation__step step-navigation__step--active' : 'step-navigation__step'}
        style={{ cursor: completedStep >= 1 ? 'pointer' : 'auto' }}
        onClick={() => {
          if (completedStep >= 1) {
            setStep(1);
          }
        }}
      >
        <span className='step-navigation__step-nr'>1</span>
      </div>

      <div
        className={step === 2 ? 'step-navigation__step step-navigation__step--active' : 'step-navigation__step'}
        style={{ cursor: completedStep >= 2 ? 'pointer' : 'auto' }}
        onClick={() => {
          if (completedStep >= 2) {
            setStep(2);
          }
        }}
      >
        <span
          className={
            completedStep >= 2
              ? 'step-navigation__step-nr'
              : 'step-navigation__step-nr step-navigation__step-nr--future'
          }
        >
          2
        </span>
      </div>

      <div
        className={step === 3 ? 'step-navigation__step step-navigation__step--active' : 'step-navigation__step'}
        style={{ cursor: completedStep >= 3 ? 'pointer' : 'auto' }}
        onClick={() => {
          if (completedStep >= 3) {
            setStep(3);
          }
        }}
      >
        <span
          className={
            completedStep >= 3
              ? 'step-navigation__step-nr'
              : 'step-navigation__step-nr step-navigation__step-nr--future'
          }
        >
          3
        </span>
      </div>

      <div
        className={step === 4 ? 'step-navigation__step step-navigation__step--active' : 'step-navigation__step'}
        style={{ cursor: completedStep >= 4 ? 'pointer' : 'auto' }}
        onClick={() => {
          if (completedStep >= 4) {
            setStep(4);
          }
        }}
      >
        <span
          className={
            completedStep >= 4
              ? 'step-navigation__step-nr'
              : 'step-navigation__step-nr step-navigation__step-nr--future'
          }
        >
          4
        </span>
      </div>

      <div
        className={step === 5 ? 'step-navigation__step step-navigation__step--active' : 'step-navigation__step'}
        style={{ cursor: completedStep >= 5 ? 'pointer' : 'auto' }}
        onClick={() => {
          if (completedStep >= 5) {
            setStep(5);
          }
        }}
      >
        <span
          className={
            completedStep >= 5
              ? 'step-navigation__step-nr'
              : 'step-navigation__step-nr step-navigation__step-nr--future'
          }
        >
          5
        </span>
      </div>

      <div className={step === 6 ? 'step-navigation__step step-navigation__step--active' : 'step-navigation__step'}>
        <span
          className={
            step > 6 ? 'step-navigation__step-nr' : 'step-navigation__step-nr step-navigation__step-nr--future'
          }
        >
          6
        </span>
      </div>
    </div>
  );
};

export default StepNavigation;

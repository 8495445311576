import React, { useContext, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Axios from 'axios';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import { UserContext } from '../../App';
import { Button, Layout, notification } from 'antd';
import CategoryForm from '../../components/forms/CategoryForm';
import HeaderComponent from '../../components/base/HeaderComponent';

const EditCategory = (props) => {
  const currentuser = useContext(UserContext);
  const history = useHistory();
  const [category, fetchCategory] = useAxios('', {}, currentuser.data.token, 'get');
  const { categoryId } = props.match.params;

  useEffect(() => {
    if (categoryId) fetchCategory(`${SERVER_URL}/categories/${categoryId}`, {});
  }, [fetchCategory, categoryId]);

  const onSubmit = async (formData, isNew) => {
    const method = isNew ? 'post' : 'put';
    const route = isNew ? `${SERVER_URL}/categories` : `${SERVER_URL}/categories/${categoryId}`;
    try {
      await Axios[method](route, formData, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      notification.success({
        message: `Category ${isNew ? 'created.' : 'updated.'}`,
        placement: 'bottomRight',
      });
      history.push('/categories');
    } catch (error) {
      const msg = error.response ? error.response.data.message : error.message ? error.message : error;
      console.log(msg);
      notification.error({
        message: msg,
        placement: 'bottomRight',
      });
    }
  };

  const isDataFetched = !category.isLoading && category.data && currentuser.language;

  return (
    <Layout className='manage-layout'>
      <div
        style={{ textAlign: 'center', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}
      >
        <HeaderComponent text='Add new Category' />
        <div className='form-wrapper'>
          {!categoryId && isDataFetched && (
            <CategoryForm
              isNew={true}
              language={currentuser.language}
              onSubmit={onSubmit}
              SERVER_URL={SERVER_URL}
              token={currentuser.data.token}
            />
          )}
          {categoryId && !category.isError && category.data && isDataFetched && (
            <CategoryForm
              isNew={false}
              data={category.data}
              language={currentuser.language}
              onSubmit={onSubmit}
              SERVER_URL={SERVER_URL}
              token={currentuser.data.token}
            />
          )}
        </div>
      </div>
    </Layout>
  );
};

export default EditCategory;

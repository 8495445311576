/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect } from 'react';
import useAxios from '../hooks/useAxios';
import { SERVER_URL } from '../config';
import { Layout, Skeleton } from 'antd';
import { UserContext } from '../App';
import HeaderComponent from '../components/base/HeaderComponent';
import HomebaseWidget from '../components/homebase/HomebaseWidget';
import HomebaseStatsList from '../components/homebase/HomebaseStatsList';
import HomebaseCard from '../components/homebase/HomebaseCard';
import HomebaseLicensesList from '../components/homebase/HomebaseLicensesList';
import { ReactComponent as ManagePatientsImage } from '../icons/homebase-icons/manage-patients.svg';
import { ReactComponent as ManageStudiesImage } from '../icons/homebase-icons/manage-studies.svg';
import { ReactComponent as ManageUsersImage } from '../icons/homebase-icons/manage-users.svg';
import { ReactComponent as ManageOrganizationImage } from '../icons/homebase-icons/manage-organization.svg';

const { Content } = Layout;

const Home = () => {
  const user = useContext(UserContext);
  const isAdmin = user?.data?.role.some((r) => r === 'admin');
  const [statistics, fetchStatistics] = useAxios('', null, user.data.token, 'get');

  useEffect(() => {
    fetchStatistics(`${SERVER_URL}/clients-admin-homebase-statistics`, null, user.data.token, 'get');
  }, [fetchStatistics, user.data.token]);

  const overviewStatsOne = {
    targetPatients: statistics?.data?.totalPatientsCreated,
    plannedStudies: statistics?.data?.totalStudies,
    totalSites: statistics?.data?.totalSites,
    totalUsers: statistics?.data?.totalUsers,
  };
  const overviewStatsTwo = {
    activePatients: statistics?.data?.activePatients,
    activeStudies: statistics?.data?.activeStudies,
    activeSites: statistics?.data?.activeSites,
    activeUsers: statistics?.data?.activeUsers,
  };
  const qStats = {
    totalQoos: statistics?.data?.totalQoos,
    totalQoolections: statistics?.data?.totalQoolections,
  };
  const userStats = {
    totalTime: statistics?.data?.totalTime,
    averageTime: statistics?.data?.averageTime,
  };
  const licensesStats = {
    patientLicenses: `${statistics?.data?.totalPatientLicencesUsed} / ${statistics?.data?.totalPatientLicences}`,
    monitorLicenses: `${statistics?.data?.totalMonitorLicencesUsed} / ${statistics?.data?.totalMonitorLicences}`,
    creatorLicenses: `${statistics?.data?.totalCreatorLicencesUsed} / ${statistics?.data?.totalCreatorLicences}`,
  };

  return (
    <Layout className='homebase__layout'>
      <HeaderComponent text={`Welcome to QClinical, ${user.data.firstName} ${user.data.lastName}`} />
      <Content className='homebase__wrapper'>
        {isAdmin && (
          <div className='homebase__content'>
            <HomebaseWidget maxWidth='630px' title={'What do you want to do today?'}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div className='flex-row' style={{ marginBottom: '30px' }}>
                  <HomebaseCard
                    textContent='Add a new patient or manage existing ones with ease.
                    Access the Manage Patients section now to get started.'
                    image={<ManagePatientsImage />}
                    buttonText='Manage Patients'
                    buttonLink='/patients'
                  />
                  <HomebaseCard
                    textContent='Easily add a new study or manage an existing one in just a few clicks. Head to the Manage Studies section to take action now.'
                    image={<ManageStudiesImage />}
                    buttonText='Manage Studies'
                    buttonLink='/studies'
                  />
                </div>
                <div className='flex-row'>
                  <HomebaseCard
                    textContent='Add new users or manage existing ones in one place. Visit the Manage Users section to get started today.'
                    image={<ManageUsersImage />}
                    buttonText='Manage Users'
                    buttonLink='/client-users'
                  />
                  <HomebaseCard
                    textContent='Effortlessly add, edit, or manage information about your organization. Access the Manage Organization section now.'
                    image={<ManageOrganizationImage />}
                    buttonText='Manage Organization'
                    buttonLink='/teams'
                  />
                </div>
              </div>
            </HomebaseWidget>
            <section className='homebase__column'>
              <HomebaseWidget title={'Overview'}>
                <div className='homebase__list-group'>
                  {statistics?.isLoading ? (
                    <Skeleton active />
                  ) : (
                    <>
                      <HomebaseStatsList
                        group={'overview'}
                        data={overviewStatsOne}
                        loading={false}
                        setLoading={() => {}}
                      />
                      <HomebaseStatsList
                        group={'overview'}
                        data={overviewStatsTwo}
                        loading={false}
                        setLoading={() => {}}
                      />
                    </>
                  )}
                </div>
              </HomebaseWidget>
              <div className='flex-row'>
                <HomebaseWidget title={'Q-Stats'}>
                  {statistics?.isLoading ? (
                    <Skeleton active />
                  ) : (
                    <HomebaseStatsList group={'qStats'} data={qStats} loading={false} setLoading={() => {}} single />
                  )}
                </HomebaseWidget>
                <HomebaseWidget title={'User Stats'}>
                  {statistics?.isLoading ? (
                    <Skeleton active />
                  ) : (
                    <HomebaseStatsList
                      group={'userStats'}
                      data={userStats}
                      loading={false}
                      setLoading={() => {}}
                      single
                    />
                  )}
                </HomebaseWidget>
              </div>
            </section>
            <section className='homebase__column'>
              <HomebaseWidget title={'Licenses'}>
                {statistics?.isLoading ? (
                  <Skeleton active />
                ) : (
                  <HomebaseLicensesList group={'licenses'} data={licensesStats} loading={false} setLoading={() => {}} />
                )}
              </HomebaseWidget>
              {/* <HomebaseWidget></HomebaseWidget> */}
            </section>
          </div>
        )}
      </Content>
    </Layout>
  );
};

export default Home;

import { ReactComponent as NoIcon } from '../icons/homebase-icons/no-icon.svg';
import { ReactComponent as TargetPatientsIcon } from '../icons/homebase-icons/target-participants.svg';
import { ReactComponent as PlannedStudiesIcon } from '../icons/homebase-icons/planned-studies.svg';
import { ReactComponent as TotalSitesIcon } from '../icons/homebase-icons/total-sites.svg';
import { ReactComponent as TotalUsersIcon } from '../icons/homebase-icons/total-users.svg';
import { ReactComponent as ActivePatientsIcon } from '../icons/homebase-icons/in-study-participants.svg';
import { ReactComponent as ActiveStudiesIcon } from '../icons/homebase-icons/active-studies.svg';
import { ReactComponent as ActiveSitesIcon } from '../icons/homebase-icons/active-sites.svg';
import { ReactComponent as ActiveUsersIcon } from '../icons/homebase-icons/active-users.svg';
import { ReactComponent as TotalQoosIcon } from '../icons/homebase-icons/total-qoos.svg';
import { ReactComponent as TotalQoolectionsIcon } from '../icons/homebase-icons/total-qoolections.svg';
import { ReactComponent as TotalTimeIcon } from '../icons/homebase-icons/total-time.svg';
import { ReactComponent as MonitorLicensesIcon } from '../icons/homebase-icons/monitor-licenses.svg';
import { ReactComponent as CreatorLicensesIcon } from '../icons/homebase-icons/creator-licenses.svg';

const homebaseStats = {
  noicon: {
    icon: <NoIcon />,
  },
  targetPatients: {
    icon: <TargetPatientsIcon />,
    label: 'Target Patients',
    type: 'number',
    group: 'overview',
    tooltip: 'The total number of patient licenses purchased across all studies in QClinical.',
  },
  plannedStudies: {
    icon: <PlannedStudiesIcon />,
    label: 'Planned Studies',
    type: 'number',
    group: 'overview',
    tooltip: 'The total number of studies that are planned in QClinical.',
  },
  totalSites: {
    icon: <TotalSitesIcon />,
    label: 'Total Sites',
    type: 'number',
    group: 'overview',
    tooltip: 'The total number of sites for all studies that are planned in QClinical.',
  },
  totalUsers: {
    icon: <TotalUsersIcon />,
    label: 'Total Users',
    type: 'number',
    group: 'overview',
    tooltip: 'The total number of user (creator + monitor) licenses purchased for QClinical.',
  },
  activePatients: {
    icon: <ActivePatientsIcon />,
    label: 'Active Patients',
    type: 'number',
    group: 'overview',
    tooltip: 'The total number of patients that are currently participating or have completed a study in QClinical.',
  },
  activeStudies: {
    icon: <ActiveStudiesIcon />,
    label: 'Active Studies',
    type: 'number',
    group: 'overview',
    tooltip: 'The total number of studies that have at least 1 active or completed patient in QClinical.',
  },
  activeSites: {
    icon: <ActiveSitesIcon />,
    label: 'Active Sites',
    type: 'number',
    group: 'overview',
    tooltip: 'The total number of sites that have at least 1 active or completed patient in QClinical.',
  },
  activeUsers: {
    icon: <ActiveUsersIcon />,
    label: 'Active Users',
    type: 'number',
    group: 'overview',
    tooltip:
      'The total number of users (creators + monitors) that have logged in to QClinical during the selected time horizon.',
  },
  totalQoos: {
    icon: <TotalQoosIcon />,
    label: 'Total Qoos',
    type: 'number',
    group: 'qStats',
    tooltip: 'The total number of approved Qoos created by all users.',
  },
  totalQoolections: {
    icon: <TotalQoolectionsIcon />,
    label: 'Total Qoolections',
    type: 'number',
    group: 'qStats',
    tooltip: 'The total number of approved Qoolections created by all users.',
  },
  totalTime: {
    icon: <TotalTimeIcon />,
    label: 'Total Time',
    type: 'minutes',
    group: 'userStats',
    tooltip: 'The total time spent in QClinical by all active users during the selected time horizon.',
  },
  averageTime: {
    icon: <TotalTimeIcon />,
    label: 'Average Time',
    type: 'minutes',
    group: 'userStats',
    tooltip: 'The average time spent in QClinical by all active users during the selected time horizon.',
  },
  patientLicenses: {
    icon: <TotalUsersIcon />,
    label: 'Patient',
    type: 'countOf',
    group: 'licenses',
    tooltip: 'The total number of patient licenses purchased across all studies in QClinical.',
  },
  monitorLicenses: {
    icon: <MonitorLicensesIcon />,
    label: 'Monitor',
    type: 'countOf',
    group: 'licenses',
    tooltip: 'The total number of monitor licenses purchased for QClinical.',
  },
  creatorLicenses: {
    icon: <CreatorLicensesIcon />,
    label: 'Creator',
    type: 'countOf',
    group: 'licenses',
    tooltip: 'The total number of creator licenses purchased for QClinical.',
  },
};

export default homebaseStats;

import React, { useEffect } from 'react';
import { Tabs, Divider, Input, Button, Form, Row, Col } from 'antd';

const formInit = {
  _id: null,
  name: '',
};

const BlockTypeForm = ({ isNew, data, onSubmit, language }) => {
  const [form] = Form.useForm();
  let initialValues = { ...formInit, ...data };
  useEffect(() => form.resetFields());
  const onFinish = async (values, isNew) => onSubmit({ ...values, }, isNew);

  return (
    <div className='panel panel-body'>
      <div className='panel-body'>
        <Form className='form-horizontal' initialValues={initialValues} onFinish={(values) => onFinish(values, !data)} layout='vertical' form={form}>
          <Row type='flex' gutter={16}>
            <Col xs={24} md={16}>
              <div className='panel-body'>
                <Tabs tabPosition='left' defaultActiveKey={language.default.code}>
                  {language.list.map((lang) => (
                    <Tabs.TabPane tab={lang.code} key={lang.code} forceRender>
                      <Form.Item
                        label='Name'
                        rules={[{ required: true, message: 'Please enter name!' }]}
                        name='name'
                      >
                        <Input />
                      </Form.Item>
                    </Tabs.TabPane>
                  ))}
                </Tabs>

                <Divider type='horizontal' />
              </div>
            </Col>

          </Row>

          <div className='text-right'>
            <Button type='primary' htmlType='submit'>
              {isNew ? 'Add' : 'update'} Block Type
            </Button>
          </div>
        </Form>

      </div>
    </div>
  );
};

export default BlockTypeForm;

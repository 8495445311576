import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../App';
import Axios from 'axios';
import { Layout, notification } from 'antd';
import { getTreeFromFlatData } from 'react-sortable-tree';
import HeaderComponent from '../../components/base/HeaderComponent';
import { SERVER_URL } from '../../config';
import SettingsStepOrganisation from './SettingsStepOrganisation';
import SettingsStepStudies from './SettingsStepStudies';
import SettingsStepTeams from './SettingsStepTeams';
import SettingsStepUsers from './SettingsStepUsers';
import SettingsStepPatients from './SettingsStepPatients';
import SettingsStepPersonnels from './SettingsStepPersonnels';
import { tr } from 'date-fns/locale';

const CompanySettingsBuilder = () => {
  const [completedStep, setCompletedStep] = useState(null);
  const currentuser = useContext(UserContext);
  const clientId = currentuser?.data?.client?._id;
  const [client, setClient] = useState({});
  const [step, setStep] = useState(1);
  const [branches, setBranches] = useState([]);
  const [treeData, setTreeData] = useState([]);
  const [studies, setStudies] = useState([]);
  const [studyForEdit, setStudyForEdit] = useState(null);
  const [teams, setTeams] = useState([]);
  const [teamForEdit, setTeamForEdit] = useState(null);
  const [users, setUsers] = useState([]);
  const [userForEdit, setUserForEdit] = useState(null);
  const [patients, setPatients] = useState([]);
  const [patientForEdit, setPatientForEdit] = useState(null);
  const [personnels, setPersonnels] = useState([]);
  const [personnelForEdit, setPersonnelForEdit] = useState(null);

  const sortDataForTree = (data) => {
    try {
      const formatedData = getTreeFromFlatData({
        flatData: data.map((node) => ({ ...node, title: node.branchName, key: node._id })),
        getKey: (node) => node._id, // resolve a node's key
        getParentKey: (node) => node.parentBranch, // resolve a node's parent's key
        rootKey: null, // The value of the parent key when there is no parent (i.e., at root level)
      });
      return formatedData;
    } catch (error) {
      console.log(error);
    }
  };

  const getBranches = async () => {
    try {
      const response = await Axios.get(`${SERVER_URL}/client-branches`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      if (response?.data?.items) {
        const data = sortDataForTree(response.data.items);
        setBranches(response.data.items);
        setTreeData(data);
      } else setTreeData([]);
    } catch (error) {
      console.log(error);
    }
  };

  const getClient = async () => {
    try {
      const response = await Axios.get(`${SERVER_URL}/clients/${clientId}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      if (response?.data) {
        setClient(response.data);
      } else setClient({});
    } catch (error) {
      console.log(error);
    }
  };

  const getStudies = async () => {
    try {
      const response = await Axios.get(`${SERVER_URL}/studies`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      if (response?.data?.items) {
        setStudies(response.data.items);
      } else setStudies([]);
    } catch (error) {
      console.log(error);
    }
  };

  const getStudyForEdit = async (id) => {
    try {
      const response = await Axios.get(`${SERVER_URL}/studies/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      if (response?.data) {
        setStudyForEdit(response.data);
      } else setStudyForEdit(null);
    } catch (error) {
      console.log(error);
    }
  };

  const getTeams = async () => {
    try {
      const response = await Axios.get(`${SERVER_URL}/teams`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      if (response?.data?.items) {
        setTeams(response.data.items);
      } else setTeams([]);
    } catch (error) {
      console.log(error);
    }
  };

  const getTeamForEdit = async (id) => {
    try {
      const response = await Axios.get(`${SERVER_URL}/teams/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      if (response?.data) {
        setTeamForEdit(response.data);
      } else setTeamForEdit(null);
    } catch (error) {
      console.log(error);
    }
  };

  const getUsers = async () => {
    try {
      const response = await Axios.get(`${SERVER_URL}/client-users`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      if (response?.data?.items) {
        setUsers(response.data.items);
      } else setUsers([]);
    } catch (error) {
      console.log(error);
    }
  };

  const getUserForEdit = async (id) => {
    try {
      const response = await Axios.get(`${SERVER_URL}/users/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      if (response?.data) {
        setUserForEdit(response.data);
      } else setUserForEdit(null);
    } catch (error) {
      console.log(error);
    }
  };

  const getPatients = async () => {
    try {
      const response = await Axios.get(`${SERVER_URL}/patients?filter=${JSON.stringify({ type: 'patient' })}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      if (response?.data?.items) {
        setPatients(response.data.items);
      } else setPatients([]);
    } catch (error) {
      console.log(error);
    }
  };

  const getPatientForEdit = async (id) => {
    try {
      const response = await Axios.get(`${SERVER_URL}/patients/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      if (response?.data) {
        setPatientForEdit(response.data);
      } else setPatientForEdit(null);
    } catch (error) {
      console.log(error);
    }
  };

  const getPersonnels = async () => {
    try {
      const response = await Axios.get(`${SERVER_URL}/patients?filter=${JSON.stringify({ type: 'personnel' })}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      if (response?.data?.items) {
        setPersonnels(response.data.items);
      } else setPersonnels([]);
    } catch (error) {
      console.log(error);
    }
  };

  const getPersonnelForEdit = async (id) => {
    try {
      const response = await Axios.get(`${SERVER_URL}/patients/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      if (response?.data) {
        setPersonnelForEdit(response.data);
      } else setPersonnelForEdit(null);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getClient();
    getBranches();
    getStudies();
    getTeams();
    getPersonnels();
    getUsers();
    getPatients();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    setClient,
    setTreeData,
    setStudies,
    setUsers,
    setPatients,
    setPersonnels,
    setStudyForEdit,
    setTeamForEdit,
    setUserForEdit,
    setPatientForEdit,
    setPersonnelForEdit,
    setCompletedStep,
  ]);

  const clientData = client?._id ? client : undefined;

  const changeCompanyLevels = async (levelNumber, level1, level2, level3, id) => {
    const data = { levelNumber, level1, level2, level3, id };
    try {
      const response = await Axios.put(`${SERVER_URL}/change-client-organisation-level`, data, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      if (response?.data.message === 'Updated') setCompletedStep(1);
      notification.success({ message: 'Company levels successfully updated', placement: 'topLeft', duration: 2 });
      getClient();
      getBranches();
    } catch (error) {
      console.log(error);
      notification.error({ message: error?.response?.data?.message, placement: 'topLeft', duration: 2 });
    }
  };

  const treeDataChangeHandler = async (formData, isNew) => {
    const method = isNew ? 'post' : 'put';
    const route = isNew ? `${SERVER_URL}/client-branches` : `${SERVER_URL}/client-branches/${formData.id}`;
    try {
      await Axios[method](route, formData, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: `Branch ${isNew ? 'added' : 'updated'} successfully`,
        placement: 'topLeft',
        duration: 2,
      });
      getBranches();
    } catch (error) {
      console.log(error);
      notification.error({ message: error?.response?.data?.message, placement: 'topLeft', duration: 2 });
    }
  };

  const treeBranchMoveHandler = async (data) => {
    const changedBranch = data.node || null;
    const parentBranch = data.nextParentNode || null;
    if (parentBranch && changedBranch.parentBranch !== parentBranch._id) {
      try {
        changedBranch.parentBranch = parentBranch._id;
        await Axios.put(`${SERVER_URL}/client-branches/${changedBranch._id}`, changedBranch, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        });
        getBranches();
        notification.success({ message: 'Branch relocated successfully', placement: 'topLeft', duration: 2 });
      } catch (error) {
        console.log(error);
        notification.error({ message: error?.response?.data?.message, placement: 'topLeft', duration: 2 });
      }
    }
  };

  const treeDeleteDataHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/client-branches/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({ message: 'Branch deleted successfully', placement: 'topLeft', duration: 2 });
      getBranches();
    } catch (error) {
      console.log(error);
      notification.error({ message: error?.response?.data?.message, placement: 'topLeft', duration: 2 });
    }
  };

  const addAndEditStudyHandler = async (formData, isNew) => {
    const method = isNew ? 'post' : 'put';
    const route = isNew ? `${SERVER_URL}/studies` : `${SERVER_URL}/studies/${studyForEdit._id}`;
    try {
      await Axios[method](route, formData, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: `Study ${isNew ? 'added' : 'updated'} successfully`,
        placement: 'topLeft',
        duration: 2,
      });
      setStudyForEdit(null);
      getStudies();
    } catch (error) {
      console.log(error);
      notification.error({ message: error?.response?.data?.message, placement: 'topLeft', duration: 2 });
    }
  };

  const deleteStudyHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/studies/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({ message: 'Study deleted successfully', placement: 'topLeft', duration: 2 });
      getStudies();
    } catch (error) {
      console.log(error);
      notification.error({ message: error?.response?.data?.message, placement: 'topLeft', duration: 2 });
    }
  };

  const addAndEditTeamHandler = async (formData, isNew) => {
    const method = isNew ? 'post' : 'put';
    const route = isNew ? `${SERVER_URL}/teams` : `${SERVER_URL}/teams/${teamForEdit._id}`;
    try {
      await Axios[method](route, formData, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: `Team ${isNew ? 'added' : 'updated'} successfully`,
        placement: 'topLeft',
        duration: 2,
      });
      setTeamForEdit(null);
      getTeams();
    } catch (error) {
      console.log(error);
      notification.error({ message: error?.response?.data?.message, placement: 'topLeft', duration: 2 });
    }
  };

  const deleteTeamHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/teams/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      getTeams();
      notification.success({ message: 'Team deleted successfully', placement: 'topLeft', duration: 2 });
    } catch (error) {
      console.log(error);
      notification.error({ message: error?.response?.data?.message, placement: 'topLeft', duration: 2 });
    }
  };

  const addAndEditClientUserHandler = async (formData, isNew) => {
    const method = isNew ? 'post' : 'put';
    const route = isNew ? `${SERVER_URL}/register` : `${SERVER_URL}/users/${userForEdit._id}`;
    try {
      await Axios[method](
        route,
        { ...formData, client: clientId },
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        },
      );
      notification.success({
        message: `User ${isNew ? 'added' : 'updated'} successfully`,
        placement: 'topLeft',
        duration: 2,
      });
      setUserForEdit(null);
      getClient();
      getUsers();
    } catch (error) {
      console.log(error);
      notification.error({ message: error?.response?.data?.message, placement: 'topLeft', duration: 2 });
    }
  };

  const deleteClientUserHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/users/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({ message: 'User deleted successfully', placement: 'topLeft', duration: 2 });
      getClient();
      getUsers();
    } catch (error) {
      console.log(error);
      notification.error({ message: error?.response?.data?.message, placement: 'topLeft', duration: 2 });
    }
  };

  const bulkAddBStudiesAndTeamsHandler = async (studyData, teamData) => {
    console.log('studyData', studyData, 'teamData', teamData);
    try {
      await Axios.post(`${SERVER_URL}/studies-bulk-add`, studyData, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      await Axios.post(`${SERVER_URL}/teams-bulk-add`, teamData, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({ message: 'Study and Teams added successfully', placement: 'topLeft', duration: 2 });
      getStudies();
      getTeams();
    } catch (error) {
      console.log(error);
      notification.error({ message: error?.response?.data?.message, placement: 'topLeft', duration: 2 });
    }
  };

  const bulkAddUsersHandler = async (data) => {
    try {
      await Axios.post(`${SERVER_URL}/users-bulk-add`, data, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({ message: 'Users added successfully', placement: 'topLeft', duration: 2 });
      getClient();
      getUsers();
    } catch (error) {
      console.log(error);
      notification.error({ message: error?.response?.data?.message, placement: 'topLeft', duration: 2 });
    }
  };

  const bulkAddPatientsHandler = async (data) => {
    try {
      await Axios.post(`${SERVER_URL}/patients-bulk-add`, data, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({ message: 'Patients added successfully', placement: 'topLeft', duration: 2 });
      getPatients();
    } catch (error) {
      console.log(error);
      notification.error({ message: error?.response?.data?.message, placement: 'topLeft', duration: 2 });
    }
  };

  const bulkAddPersonnelsHandler = async (data) => {
    try {
      await Axios.post(`${SERVER_URL}/personnels-bulk-add`, data, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({ message: 'Personnels added successfully', placement: 'topLeft', duration: 2 });
      getPersonnels();
    } catch (error) {
      console.log(error);
      notification.error({ message: error?.response?.data?.message, placement: 'topLeft', duration: 2 });
    }
  };

  const downloadProductTemplateHandler = async () => {
    try {
      const response = await Axios.get(`${SERVER_URL}/bulk-upload-product-template`, {
        responseType: 'blob',
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Teams_Studies_Upload_Template.xlsx');
      link.click();
      link.href = '';
      notification.success({ message: 'Template downloaded.', placement: 'topLeft', duration: 2 });
    } catch (error) {
      console.log(error);
      notification.error({ message: error?.response?.data?.message, placement: 'topLeft', duration: 2 });
    }
  };

  const downloadUserTemplateHandler = async () => {
    try {
      const response = await Axios.get(`${SERVER_URL}/bulk-upload-user-template`, {
        responseType: 'blob',
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'User_Upload_Template.xlsx');
      link.click();
      link.href = '';
      notification.success({ message: 'Template downloaded.', placement: 'topLeft', duration: 2 });
    } catch (error) {
      console.log(error);
      notification.error({ message: error?.response?.data?.message, placement: 'topLeft', duration: 2 });
    }
  };

  const deletePatientHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/patients/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      getPatients();
      notification.success({ message: 'Patient deleted successfully', placement: 'topLeft', duration: 2 });
    } catch (error) {
      console.log(error);
      notification.error({ message: error?.response?.data?.message, placement: 'topLeft', duration: 2 });
    }
  };

  const addAndEditPatientHandler = async (formData, isNew) => {
    console.log('Data', formData);
    console.log('isNew', isNew);
    const method = isNew ? 'post' : 'put';
    const route = isNew ? `${SERVER_URL}/patients` : `${SERVER_URL}/patients/${patientForEdit._id}`;
    try {
      await Axios[method](
        route,
        { ...formData, type: 'patient' },
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        },
      );
      notification.success({
        message: `Patient ${isNew ? 'Added' : 'Updated'} successfully`,
        placement: 'topLeft',
        duration: 2,
      });
      setPatientForEdit(null);
      getPatients();
    } catch (error) {
      console.log(error);
      notification.error({ message: error?.response?.data?.message, placement: 'topLeft', duration: 2 });
    }
  };

  const downloadPatientsTemplateHandler = async () => {
    try {
      const response = await Axios.get(`${SERVER_URL}/bulk-upload-patient-template`, {
        responseType: 'blob',
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Patient_Upload_Template.xlsx');
      link.click();
      link.href = '';
      notification.success({ message: 'Template downloaded.', placement: 'topLeft', duration: 2 });
    } catch (error) {
      console.log(error);
      notification.error({ message: error?.response?.data?.message, placement: 'topLeft', duration: 2 });
    }
  };

  const deletePersonnelHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/patients/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      getPersonnels();
      notification.success({ message: 'Personnel deleted successfully', placement: 'topLeft', duration: 2 });
    } catch (error) {
      console.log(error);
      notification.error({ message: error?.response?.data?.message, placement: 'topLeft', duration: 2 });
    }
  };

  const addAndEditPersonnelHandler = async (formData, isNew) => {
    const method = isNew ? 'post' : 'put';
    const route = isNew ? `${SERVER_URL}/patients` : `${SERVER_URL}/patients/${personnelForEdit._id}`;
    try {
      await Axios[method](
        route,
        { ...formData, type: 'personnel' },
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        },
      );
      notification.success({
        message: `Personnel ${isNew ? 'Added' : 'Updated'} successfully`,
        placement: 'topLeft',
        duration: 2,
      });
      setPersonnelForEdit(null);
      getPersonnels();
    } catch (error) {
      console.log(error);
      notification.error({ message: error?.response?.data?.message, placement: 'topLeft', duration: 2 });
    }
  };

  const downloadPersonnelsTemplateHandler = async () => {
    try {
      const response = await Axios.get(`${SERVER_URL}/bulk-upload-personnel-template`, {
        responseType: 'blob',
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Personnel_Upload_Template.xlsx');
      link.click();
      link.href = '';
      notification.success({ message: 'Template downloaded.', placement: 'topLeft', duration: 2 });
    } catch (error) {
      console.log(error);
      notification.error({ message: error?.response?.data?.message, placement: 'topLeft', duration: 2 });
    }
  };

  const setupFinished = currentuser?.data?.client?.setupFinished;
  console.log('setupFinished: ', setupFinished);

  return (
    <Layout className='manage-layout'>
      <div
        style={{ textAlign: 'center', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}
      >
        <HeaderComponent text='Welcome to your QClinical® Company Settings' />
        <div className='company-settings-builder'>
          {step === 1 && clientData && clientData.companyName && treeData && (
            <SettingsStepOrganisation
              step={step}
              setStep={setStep}
              client={clientData}
              personnels={personnels}
              treeData={treeData}
              treeDataChangeHandler={treeDataChangeHandler}
              treeBranchMoveHandler={treeBranchMoveHandler}
              treeDeleteDataHandler={treeDeleteDataHandler}
              setTreeData={setTreeData}
              changeCompanyLevels={changeCompanyLevels}
              completedStep={completedStep}
              setCompletedStep={setCompletedStep}
            />
          )}
          {step === 2 && studies && (
            <SettingsStepStudies
              step={step}
              setStep={setStep}
              SERVER_URL={SERVER_URL}
              studies={studies}
              getStudyForEdit={getStudyForEdit}
              setStudyForEdit={setStudyForEdit}
              studyForEdit={studyForEdit}
              deleteStudyHandler={deleteStudyHandler}
              addAndEditStudyHandler={addAndEditStudyHandler}
              completedStep={completedStep}
              setCompletedStep={setCompletedStep}
              bulkAddBStudiesAndTeamsHandler={bulkAddBStudiesAndTeamsHandler}
              downloadProductTemplateHandler={downloadProductTemplateHandler}
            />
          )}
          {step === 3 && studies && studies.length > 0 && teams && (
            <SettingsStepTeams
              step={step}
              setStep={setStep}
              teams={teams}
              studies={studies}
              getTeamForEdit={getTeamForEdit}
              setTeamForEdit={setTeamForEdit}
              teamForEdit={teamForEdit}
              deleteTeamHandler={deleteTeamHandler}
              addAndEditTeamHandler={addAndEditTeamHandler}
              completedStep={completedStep}
              setCompletedStep={setCompletedStep}
            />
          )}
          {step === 4 && teams && branches && users && clientData && clientData.companyName && (
            <SettingsStepUsers
              step={step}
              setStep={setStep}
              client={clientData}
              teams={teams}
              branches={branches}
              users={users}
              getUserForEdit={getUserForEdit}
              setUserForEdit={setUserForEdit}
              userForEdit={userForEdit}
              deleteClientUserHandler={deleteClientUserHandler}
              addAndEditClientUserHandler={addAndEditClientUserHandler}
              bulkAddUsersHandler={bulkAddUsersHandler}
              downloadUserTemplateHandler={downloadUserTemplateHandler}
              completedStep={completedStep}
              setCompletedStep={setCompletedStep}
            />
          )}
          {step === 5 && branches && (
            <SettingsStepPatients
              step={step}
              setStep={setStep}
              patients={patients}
              branches={branches}
              studies={studies}
              qoolections={[]}
              getPatientForEdit={getPatientForEdit}
              setPatientForEdit={setPatientForEdit}
              patientForEdit={patientForEdit}
              deletePatientHandler={deletePatientHandler}
              addAndEditPatientHandler={addAndEditPatientHandler}
              bulkAddPatientsHandler={bulkAddPatientsHandler}
              downloadPatientsTemplateHandler={downloadPatientsTemplateHandler}
              client={currentuser?.data?.client}
              completedStep={completedStep}
              setCompletedStep={setCompletedStep}
            />
          )}
          {step === 6 && branches && (
            <SettingsStepPersonnels
              step={step}
              setStep={setStep}
              personnels={personnels}
              branches={branches}
              qoolections={[]}
              getPersonnelForEdit={getPersonnelForEdit}
              setPersonnelForEdit={setPersonnelForEdit}
              personnelForEdit={personnelForEdit}
              deletePersonnelHandler={deletePersonnelHandler}
              addAndEditPersonnelHandler={addAndEditPersonnelHandler}
              bulkAddPersonnelsHandler={bulkAddPersonnelsHandler}
              downloadPersonnelsTemplateHandler={downloadPersonnelsTemplateHandler}
              client={currentuser?.data?.client}
              completedStep={completedStep}
              setCompletedStep={setCompletedStep}
            />
          )}
        </div>
      </div>
    </Layout>
  );
};

export default CompanySettingsBuilder;

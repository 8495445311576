import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Card, notification } from 'antd';
import { SERVER_URL } from '../config';
import Axios from 'axios';
import AuthMessage from '../components/elements/AuthMessage';
import Footer from '../components/base/Footer';

const ResetPassword = (props) => {
  const [verifyMessage, setVerifyMessage] = useState(false);
  const [resetPasswordMessage, setResetPasswordMessage] = useState('');

  useEffect(() => {
    const checkForVerification = window.location.href.split('/');
    if (checkForVerification && checkForVerification[checkForVerification.length - 1].includes('email-verified')) {
      // setVerifyMessage('Email verified, please change your password.');
      setResetPasswordMessage({
        message: 'Email verified, please change your password.',
        type: 'success',
      });
    }
  }, []);

  const [token] = window.location.pathname.split('/').splice(-1);
  console.log(token);
  const onFinish = async (values) => {
    try {
      await Axios.post(`${SERVER_URL}/password-reset/${token}`, values);
      // notification.success({ message: 'Password is reseted succesfully', position: 'topRight' });
      setTimeout(() => {
        window.location.href = '/login';
      }, 1000);
    } catch (error) {
      console.log('error: ', error);
      setResetPasswordMessage({
        message: error?.message || error?.response?.data?.message,
        type: 'error',
      });
      // notification.error({ message: error?.response?.data?.message, position: 'topRight' });
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div className='log'>
      <div className='card-wrapper'>
        <Card title='Reset password' className='login-header' bordered={false}>
          {resetPasswordMessage && <AuthMessage content={resetPasswordMessage} />}
          <p>Enter a new password below to change your password.</p>
          <Form name='basic' onFinish={onFinish} onFinishFailed={onFinishFailed}>
            <Form.Item
              label='New password'
              name='newPassword'
              hasFeedback
              rules={[
                { required: true, message: 'Please enter your new password!' },
                {
                  validator: (_, value) => {
                    // Test if password is at least 12 characters long, contains one Upper case letter, one lower case letter, and one number.
                    if (value.length >= 12 && /[A-Z]/.test(value) && /[a-z]/.test(value) && /[0-9]/.test(value)) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      'The password must contain at least 12 characters, at least one uppercase letter, and one number.',
                    );
                  },
                },
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              label='Confirm new password'
              name='confirmNewPassword'
              dependencies={['newPassword']}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: 'Please confirm your password!',
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('newPassword') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('The two passwords that you entered do not match!'));
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item style={{ marginBottom: 0 }}>
              <Button
                type='primary'
                htmlType='submit'
                style={{ backgroundColor: '#36a9e1', borderRadius: '0', width: '220px' }}
              >
                RESET PASSWORD
              </Button>
            </Form.Item>
          </Form>
        </Card>
        {/* {verifyMessage && <p style={{ marginTop: '20px' }}>{verifyMessage}</p>} */}
      </div>
      <Footer />
    </div>
  );
};
export default ResetPassword;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../App';
import Axios from 'axios';
import { notification, Layout, Button, Input, Modal, Tag, Popconfirm, Empty, Table } from 'antd';
import { ReactComponent as Magnifying } from '../../icons/magnifying-glas.svg';
import { ReactComponent as Trash } from '../../icons/ph_trash.svg';
import { ReactComponent as Edit } from '../../icons/ph_note-pencil-light.svg';
import { SERVER_URL } from '../../config';
import HeaderComponent from '../../components/base/HeaderComponent';
import TeamForm from '../../components/forms/TeamForm';

const Teams = (props) => {
  const currentuser = useContext(UserContext);
  const [teams, setTeams] = useState();
  const [studies, setStudies] = useState([]);
  const [teamForEdit, setTeamForEdit] = useState(null);
  const [newTeam, setNewTeam] = useState(null);

  const getTeams = async (search = null) => {
    const response =
      search && search.length > 1
        ? await Axios.get(`${SERVER_URL}/teams?filter=${JSON.stringify({ name: { $regex: search, $options: 'i' } })}`, {
            withCredentials: false,
            headers: { Authorization: `Bearer ${currentuser.data.token}` },
          })
        : await Axios.get(`${SERVER_URL}/teams`, {
            withCredentials: false,
            headers: { Authorization: `Bearer ${currentuser.data.token}` },
          });
    response?.data?.items ? setTeams(response.data.items) : setTeams([]);
  };

  const getStudies = async () => {
    const response = await Axios.get(`${SERVER_URL}/studies`, {
      withCredentials: false,
      headers: { Authorization: `Bearer ${currentuser.data.token}` },
    });
    response?.data?.items ? setStudies(response.data.items) : setStudies([]);
  };

  const getTeamForEdit = async (id) => {
    const response = await Axios.get(`${SERVER_URL}/teams/${id}`, {
      withCredentials: false,
      headers: { Authorization: `Bearer ${currentuser.data.token}` },
    });
    response?.data ? setTeamForEdit(response.data) : setTeamForEdit(null);
  };

  useEffect(() => {
    getTeams();
    getStudies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setTeams, setTeamForEdit, setNewTeam]);

  const deleteTeamHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/teams/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({ message: 'Team deleted successfully', placement: 'topLeft', duration: 2 });
      getTeams();
    } catch (error) {
      console.log(error);
      notification.error({
        message: error?.response?.data?.message || 'Problem with delete.',
        placement: 'topLeft',
        duration: 2,
      });
    }
  };

  const addAndEditTeamHandler = async (formData, isNew) => {
    const method = isNew ? 'post' : 'put';
    const route = isNew ? `${SERVER_URL}/teams` : `${SERVER_URL}/teams/${teamForEdit._id}`;
    try {
      await Axios[method](route, formData, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: `Team ${isNew ? 'added' : 'updated'} successfully`,
        placement: 'topLeft',
        duration: 2,
      });
      setTeamForEdit(null);
      setNewTeam(null);
      getTeams();
    } catch (error) {
      console.log(error);
      notification.error({
        message: error?.response?.data?.message || 'Problem with create/edit.',
        placement: 'topLeft',
        duration: 2,
      });
    }
  };

  const handleSearch = (e) => {
    getTeams(e);
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Studies',
      dataIndex: 'studies',
      render: (text, record) =>
        record.studies.map((study) => (
          <Tag style={{ backgroundColor: '#E5f9c6' }} key={study._id + 110}>
            {study.name}
          </Tag>
        )),
    },
    {
      title: 'Action',
      render: (text, record) => (
        <div
          style={{
            display: 'inline-flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '15px',
            color: '#90989E',
          }}
        >
          <div style={{ margin: '2px', padding: '2px', cursor: 'pointer' }}>
            <Popconfirm
              placement='left'
              title={`This will delete study`}
              onConfirm={() => deleteTeamHandler(record._id)}
              okText='Ok'
              cancelText='Cancel'
            >
              <Trash title={`Delete team`} />
            </Popconfirm>
          </div>
          <div style={{ margin: '2px', padding: '2px', cursor: 'pointer' }}>
            <Edit onClick={() => getTeamForEdit(record._id)} title={`Edit team`} style={{ textDecoration: 'none' }} />
          </div>
        </div>
      ),
    },
  ];

  return (
    <Layout className='manage-layout'>
      <div
        style={{ textAlign: 'center', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}
      >
        <HeaderComponent text='Manage teams' />
        <div className='teams-content'>
          <div className='teams-container'>
            <div className='teams-upper-part'>
              <div className='left-part'>
                <Input
                  className='inppy'
                  placeholder='Search'
                  onChange={(e) => handleSearch(e.target.value)}
                  prefix={<Magnifying style={{ marginRight: '5px' }} />}
                />
                <Button className='butty' type='primary' onClick={() => setNewTeam(true)}>
                  Add new team
                </Button>
              </div>
            </div>

            <div className='table-manage'>
              <Table
                size='middle'
                bordered
                dataSource={teams}
                columns={columns}
                locale={{ emptyText: <Empty description='No Teams, please add team.' /> }}
                rowKey='_id'
                pagination={{
                  defaultPageSize: 20,
                  position: 'bottom',
                  showSizeChanger: false,
                  pageSizeOptions: ['10', '20', '50', '100'],
                  hideOnSinglePage: false,
                }}
              />
            </div>
          </div>
        </div>
        {teamForEdit && (
          <div>
            <Modal open={teamForEdit} onCancel={() => setTeamForEdit(null)} footer={null} centered={true}>
              <div style={{ padding: '8px' }}>
                <div>
                  <TeamForm isNew={false} data={teamForEdit} studies={studies} onSubmit={addAndEditTeamHandler} />
                </div>
              </div>
            </Modal>
          </div>
        )}
        {newTeam && (
          <div>
            <Modal open={newTeam} onCancel={() => setNewTeam(null)} footer={null} centered={true}>
              <div style={{ padding: '10px 0px 40px 0px' }}>
                <div>
                  <TeamForm isNew={true} data={teamForEdit} studies={studies} onSubmit={addAndEditTeamHandler} />
                </div>
              </div>
            </Modal>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default Teams;

import React, { useEffect } from 'react';
import { Divider, Input, Button, Form, Row, Col, Select, Checkbox, InputNumber, DatePicker } from 'antd';
import moment from 'moment';
import countries from '../../lib/countries';

const formInit = {
  sector: 'Pharmaceuticals',
};

const ClientForm = ({ isNew, data, onSubmit, clients }) => {
  const [form] = Form.useForm();
  let initialValues = { ...formInit, ...data };
  useEffect(() => form.resetFields());
  if (initialValues?.licencingPeriodStart) initialValues.licencingPeriodStart = moment(data?.licencingPeriodStart);
  if (initialValues?.licencingPeriodEnd) initialValues.licencingPeriodEnd = moment(data?.licencingPeriodEnd);
  const onFinish = async (values, isNew) => {
    values.licencingPeriodEnd =
      values.licenceType === 'Monthly'
        ? moment(values.licencingPeriodStart).add(1, 'month')
        : moment(values.licencingPeriodStart).add(1, 'year');
    values.companyPrefix = values.companyPrefix.toUpperCase();
    onSubmit(values, isNew);
  };

  return (
    <>
      <Form
        className='form-horizontal'
        initialValues={initialValues}
        onFinish={(values) => onFinish(values, !data)}
        layout='vertical'
        form={form}
      >
        <Row type='flex' gutter={18}>
          <Col>
            <div className='form-wrapper'>
              <Form.Item name='companyName' rules={[{ required: true, message: 'Please provide company namer!' }]}>
                <Input placeholder='Company Name' />
              </Form.Item>

              <Form.Item
                name='companyPrefix'
                rules={[{ required: true, message: 'Please set company prefix. Length 3 characters!', min: 3, max: 3 }]}
              >
                <Input placeholder='Company Prefix' />
              </Form.Item>

              <Form.Item name='status'>
                <Select placeholder='Status'>
                  <Select.Option value='ACTIVE'>ACTIVE</Select.Option>
                  <Select.Option value='INACTIVE'>INACTIVE</Select.Option>
                </Select>
              </Form.Item>

              <Form.Item name='phone'>
                <Input placeholder='Phone' />
              </Form.Item>

              <Form.Item name='country' rules={[{ required: true, message: 'Please select country!' }]}>
                <Select placeholder='Select country' allowClear showSearch>
                  {countries &&
                    countries.map((c) => (
                      <Select.Option key={c.code} value={c.name}>
                        {c.name}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>

              <Form.Item name='state' rules={[{ required: true, message: 'Please provide state' }]}>
                <Input placeholder='State' />
              </Form.Item>

              <Form.Item name='city' rules={[{ required: true, message: 'Please provide city!' }]}>
                <Input placeholder='City' />
              </Form.Item>

              <Form.Item name='address' rules={[{ required: true, message: 'Please provide address!' }]}>
                <Input placeholder='Address' />
              </Form.Item>

              <Form.Item name='zip' rules={[{ required: true, message: 'Please provide zip!' }]}>
                <Input placeholder='Zip' />
              </Form.Item>

              <Form.Item name='sector' rules={[{ required: true, message: 'Please select sector' }]}>
                <Select placeholder='Sector'>
                  <Select.Option value='Pharmaceuticals'>Pharmaceuticals</Select.Option>
                  <Select.Option value='Biotechnology'>Biotechnology</Select.Option>
                  <Select.Option value='Medical Devices'>Medical Devices</Select.Option>
                  <Select.Option value='Diagnostics'>Diagnostics</Select.Option>
                  <Select.Option value='Consumer Health'>Consumer Health</Select.Option>
                  <Select.Option value='Animal Health'>Animal Health</Select.Option>
                  <Select.Option value='Agriculture'>Agriculture</Select.Option>
                  <Select.Option value='Chemicals'>Chemicals</Select.Option>
                  <Select.Option value='Other'>Other</Select.Option>
                </Select>
              </Form.Item>

              <Form.Item name='taxId'>
                <Input placeholder='Tax ID' />
              </Form.Item>

              <Form.Item name='website'>
                <Input placeholder='Website' />
              </Form.Item>

              <Form.Item name='parentOfClient'>
                <Select placeholder='Parent Of' mode='multiple' allowClear>
                  {clients?.data?.items &&
                    clients.data.items.map(
                      (client) =>
                        client._id !== data?._id && (
                          <Select.Option key={client._id} value={client._id}>
                            {client.companyName}
                          </Select.Option>
                        ),
                    )}
                </Select>
              </Form.Item>

              <Form.Item name='affiliateOfClient'>
                <Select placeholder='Affiliate Of' mode='multiple' allowClear>
                  {clients?.data?.items &&
                    clients.data.items.map(
                      (client) =>
                        client._id !== data?._id && (
                          <Select.Option key={client._id} value={client._id}>
                            {client.companyName}
                          </Select.Option>
                        ),
                    )}
                </Select>
              </Form.Item>

              <Form.Item name='childOfClient'>
                <Select placeholder='Child Of' allowClear>
                  {clients?.data?.items &&
                    clients.data.items.map(
                      (client) =>
                        client._id !== data?._id && (
                          <Select.Option key={client._id} value={client._id}>
                            {client.companyName}
                          </Select.Option>
                        ),
                    )}
                </Select>
              </Form.Item>
              <Form.Item>
                <Button type='primary' htmlType='submit' style={{ float: 'right' }}>
                  {isNew ? 'Add' : 'Edit'} Client
                </Button>
              </Form.Item>
            </div>
          </Col>

          <Divider type='vertical' style={{ height: 'auto' }} />

          <Col>
            <div className='form-wrapper'>
              <Row gutter={16}>
                <Col>
                  <Form.Item label='Creator Licenses' name='totalCreatorLicences'>
                    <InputNumber min={initialValues?.licencedCreators ? initialValues.licencedCreators.length : 0} />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item label='Used Creator Licenses'>
                    <InputNumber
                      min={0}
                      value={initialValues?.licencedCreators ? initialValues.licencedCreators.length : 0}
                      disabled
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col>
                  <Form.Item label='Monitor Licenses' name='totalMonitorLicences'>
                    <InputNumber min={initialValues?.licencedMonitors ? initialValues.licencedMonitors.length : 0} />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item label='Used Monitor Licenses'>
                    <InputNumber
                      min={0}
                      value={initialValues?.licencedMonitors ? initialValues.licencedMonitors.length : 0}
                      disabled
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col>
                  <Form.Item label='Patient Licenses' name='totalPatientLicences'>
                    <InputNumber min={initialValues?.licencedPatients ? initialValues.licencedPatients.length : 0} />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item label='Used Patient Licenses'>
                    <InputNumber
                      min={0}
                      value={initialValues?.licencedPatients ? initialValues.licencedPatients.length : 0}
                      disabled
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col>
                  <Form.Item
                    label='License Period Start'
                    name='licencingPeriodStart'
                    rules={[{ required: true, message: 'Please select licencing period start day' }]}
                  >
                    <DatePicker />
                  </Form.Item>
                </Col>

                <Col>
                  <Form.Item
                    label='License Type'
                    name='licenceType'
                    rules={[{ required: true, message: 'Please select licence type' }]}
                  >
                    <Select placeholder='License Type'>
                      <Select.Option value='Monthly'>Monthly</Select.Option>
                      <Select.Option value='Annual'>Annual</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default ClientForm;

import React, { useState, useEffect } from 'react';
import readXlsxFile from 'read-excel-file';
import Fuse from 'fuse.js';
import Axios from 'axios';
import { Button, Popconfirm, Table, Modal, Tag, Divider, Empty, Tabs, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import StepNavigation from './StepNavigation';
import StudyForm from '../../components/forms/StudyForm';
import { ReactComponent as Trash } from '../../icons/ph_trash.svg';
import { ReactComponent as Edit } from '../../icons/ph_note-pencil-light.svg';

const groupByStudy = (array) =>
  Object.values(
    array.reduce((a, item) => {
      const {
        name,
        team,
        therapeuticArea,
        therapeuticAreaCondition,
        internalTherapeuticArea,
        internalTherapeuticAreaCondition,
      } = item;
      if (!a[name]) a[name] = item;
      if (!a[name].team.includes(team[0])) a[name].team.push(team[0]);
      if (!a[name].internalTherapeuticArea.includes(internalTherapeuticArea[0]))
        a[name].internalTherapeuticArea.push(internalTherapeuticArea[0]);
      if (!a[name].internalTherapeuticAreaCondition.includes(internalTherapeuticAreaCondition[0]))
        a[name].internalTherapeuticAreaCondition.push(internalTherapeuticAreaCondition[0]);
      if (a[name].therapeuticArea.filter((ta) => ta._id === therapeuticArea[0]._id).length === 0)
        a[name].therapeuticArea.push(therapeuticArea[0]);
      if (a[name].therapeuticAreaCondition.filter((tc) => tc._id === therapeuticAreaCondition[0]._id).length === 0)
        a[name].therapeuticAreaCondition.push(therapeuticAreaCondition[0]);
      return a;
    }, {}),
  );

const groupByTeam = (array) =>
  Object.values(
    array.reduce((a, item) => {
      const { name, team } = item;
      if (!a[team[0]]) a[team[0]] = { team: item.team[0], study: [item.name] };
      if (!a[team[0]].study.includes(name)) a[team[0]].study.push(name);
      return a;
    }, {}),
  );

const SettingsStepStudies = ({
  step,
  setStep,
  completedStep,
  setCompletedStep,
  SERVER_URL,
  addAndEditStudyHandler,
  getStudyForEdit,
  studyForEdit,
  setStudyForEdit,
  deleteStudyHandler,
  studies,
  bulkAddBStudiesAndTeamsHandler,
  downloadProductTemplateHandler,
}) => {
  const [bulkUploadData, setBulkUploadData] = useState([]);
  const [bulkUploadTeamData, setBulkUploadTeamData] = useState([]);
  const [therapeuticArea, setTherapeuticArea] = useState([]);
  const [therapeuticAreaCondition, setTherapeuticAreaCondition] = useState([]);

  useEffect(() => {
    getTherapeuticsArea();
    getTherapeuticsAreaConditions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setBulkUploadData, bulkUploadData]);

  const getTherapeuticsArea = async () => {
    try {
      const response = await Axios.get(`${SERVER_URL}/therapeutic-area-for-brands`);
      setTherapeuticArea(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getTherapeuticsAreaConditions = async () => {
    try {
      const response = await Axios.get(`${SERVER_URL}/therapeutic-area-condition-for-brands`);
      setTherapeuticAreaCondition(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const searchTA = (term) => {
    const options = {
      // isCaseSensitive: false,
      // includeScore: false,
      // shouldSort: true,
      // includeMatches: false,
      // findAllMatches: false,
      // minMatchCharLength: 1,
      // location: 0,
      threshold: 0.5,
      // distance: 100,
      // useExtendedSearch: false,
      // ignoreLocation: false,
      // ignoreFieldNorm: false,
      // fieldNormWeight: 1,
      keys: ['name'],
    };
    const fuse = new Fuse(therapeuticArea, options);
    const found = fuse.search(term);
    return [found[0]?.item];
  };

  const searchDS = (term) => {
    const options = {
      includeScore: true,
      threshold: 0.5,
      keys: ['name'],
    };
    const fuse = new Fuse(therapeuticAreaCondition, options);
    const found = fuse.search(term);
    return [found[0]?.item];
  };

  const uploadData = (file) => {
    let newBulkUploadData = [];
    return new Promise(async (resolve) => {
      await readXlsxFile(file, { sheet: 2 }).then(async (rows) => {
        // eslint-disable-next-line array-callback-return
        const promises = rows.slice(1).map((r, index) => {
          if (r[0] && r[1] && r[2]) {
            return newBulkUploadData.push({
              key: index,
              team: [r[1].trim()],
              name: r[2].trim(),
              genericName: r[3].trim(),
              internalTherapeuticArea: [r[4].trim()],
              therapeuticArea: searchTA(r[5].trim()),
              internalTherapeuticAreaCondition: [r[6].trim()],
              therapeuticAreaCondition: searchDS(r[7].trim()),
            });
          }
        });
        await Promise.all(promises);
      });

      const group = groupByStudy(newBulkUploadData);
      const team = groupByTeam(newBulkUploadData);
      console.log('group', group);
      setBulkUploadTeamData(team);
      resolve(setBulkUploadData(group));
    });
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Therapeutic Area',
      dataIndex: 'therapeuticArea',
      render: (text, record) =>
        record.therapeuticArea.map((ta) => (
          <Tag style={{ backgroundColor: '#E5f9c6' }} key={ta._id + 110}>
            {ta.name}
          </Tag>
        )),
    },
    {
      title: 'Conditions',
      dataIndex: 'therapeuticAreaCondition',
      render: (text, record) =>
        record.therapeuticAreaCondition.map((cond) => (
          <Tag style={{ backgroundColor: '#C6e2de' }} key={cond._id + 120}>
            {cond.name}
          </Tag>
        )),
    },
    {
      title: 'Action',
      render: (text, record) => (
        <div style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center', fontSize: '15px' }}>
          <div style={{ margin: '2px', padding: '2px', cursor: 'pointer' }}>
            <Popconfirm
              placement='left'
              title={`This will delete study`}
              onConfirm={() => deleteStudyHandler(record._id)}
              okText='Ok'
              cancelText='Cancel'
            >
              <Trash title={`Delete study`} />
            </Popconfirm>
          </div>
          <div style={{ margin: '2px', padding: '2px', cursor: 'pointer' }}>
            <Edit
              onClick={() => getStudyForEdit(record._id)}
              title={`Edit study`}
              style={{ textDecoration: 'none', color: 'black' }}
            />
          </div>
        </div>
      ),
    },
  ];

  const columnsBulkUpload = [
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Generic name',
      dataIndex: 'genericName',
    },
    {
      title: 'Team',
      dataIndex: 'team',
      render: (text, record) =>
        record.team.map((t, index) => (
          <Tag style={{ backgroundColor: '#E8daf1' }} key={index + 200}>
            {t}
          </Tag>
        )),
    },
    {
      title: 'Therapeutic Area',
      dataIndex: 'therapeuticArea',
      render: (text, record) =>
        record.therapeuticArea.map((ta) => (
          <Tag style={{ backgroundColor: '#E5f9c6' }} key={ta?._id}>
            {ta?.name}
          </Tag>
        )),
    },
    {
      title: 'Disease State',
      dataIndex: 'therapeuticAreaCondition',
      render: (text, record) =>
        record.therapeuticAreaCondition.map((cond) => (
          <Tag style={{ backgroundColor: '#C6e2de' }} key={cond?._id}>
            {cond?.name}
          </Tag>
        )),
    },
  ];

  return (
    <div className='company-settings-builder__step-1'>
      <div className='company-settings-builder__left-side'>
        <h2 className='company-settings-builder__step-subtitle'>
          <span>Step 2: </span>
          <span>Add Studies.</span>
        </h2>
        <StepNavigation step={step} setStep={setStep} completedStep={completedStep} />
        <p className='company-settings-builder__intro-text'>
          Add the studies for which you will be making your Qoolections. The table on the right will populate as you
          keep adding studies.
        </p>
        <div className='company-settings-builder__forms'>
          <Tabs defaultActiveKey='1' centered className='company-settings__tabs'>
            <Tabs.TabPane tab='Manual upload' key='1'>
              <StudyForm isNew={true} onSubmit={addAndEditStudyHandler} SERVER_URL={SERVER_URL} createProcess={true} />
            </Tabs.TabPane>
            <Tabs.TabPane tab='Bulk upload' key='2'>
              <p className='company-settings-builder__intro-text'>
                Please download our preformatted template for uploading your Study and Team information{' '}
                <span
                  style={{ fontSize: 17, color: '#4962E2', cursor: 'pointer' }}
                  onClick={downloadProductTemplateHandler}
                >
                  here
                </span>
                . There are additional guidelines for using the template included on the first tab. Once you have filled
                out the template, please return here to upload the file.
              </p>
              <div className='bulk-upload-wrapper'>
                <Upload
                  className='bulk-upload-teams'
                  style={{ margin: 0 }}
                  action={uploadData}
                  accept='.xlsx'
                  name='excelData'
                >
                  <Button type='primary' style={{ margin: 0 }}>
                    {bulkUploadData && bulkUploadData.length === 0 ? 'Upload your completed template' : 'Re-upload'}
                  </Button>
                </Upload>
              </div>
              {/* <div className='table-manage'>
                <Table
                  tableLayout='fixed'
                  size='middle'
                  bordered
                  dataSource={bulkUploadData}
                  columns={columnsBulkUpload}
                  rowKey='key'
                  pagination={{
                    defaultPageSize: 10,
                    position: 'bottom',
                    showSizeChanger: false,
                    pageSizeOptions: ['10', '20', '50', '100'],
                    hideOnSinglePage: false,
                  }}
                />
              </div> */}
              {bulkUploadData && bulkUploadData.length > 0 && bulkUploadTeamData && bulkUploadTeamData.length > 0 && (
                <Button
                  type='primary'
                  className='bulk-upload-save-button'
                  onClick={() => bulkAddBStudiesAndTeamsHandler(bulkUploadData, bulkUploadTeamData)}
                >
                  Upload your completed template
                </Button>
              )}
            </Tabs.TabPane>
          </Tabs>
        </div>
        <div className='company-settings-builder__bottom-row'>
          <Button className='company-settings-builder__step-btn' onClick={() => setStep(1)}>
            Back
          </Button>
          <Button
            id={'company-settings_next-step'}
            className='company-settings-builder__step-btn'
            type='primary'
            disabled={studies.length === 0}
            onClick={() => {
              if (completedStep === 1) setCompletedStep(2);
              setStep(3);
            }}
          >
            Next
          </Button>
        </div>
      </div>

      <Divider type='vertical' style={{ height: 'auto' }} />

      <div className='table-manage'>
        <Table
          size='middle'
          bordered
          dataSource={studies}
          columns={columns}
          locale={{ emptyText: <Empty description='No Studies, please add study.' /> }}
          rowKey='_id'
          pagination={{
            defaultPageSize: 20,
            position: 'bottom',
            showSizeChanger: false,
            pageSizeOptions: ['10', '20', '50', '100'],
            hideOnSinglePage: false,
          }}
        />
      </div>

      {studyForEdit && (
        <div>
          <Modal open={studyForEdit} onCancel={() => setStudyForEdit(null)} footer={null} centered={true}>
            <div style={{ padding: '8px' }}>
              <div>
                <StudyForm
                  isNew={false}
                  data={studyForEdit}
                  onSubmit={addAndEditStudyHandler}
                  SERVER_URL={SERVER_URL}
                />
              </div>
            </div>
          </Modal>
        </div>
      )}
    </div>
  );
};

export default SettingsStepStudies;

import React, { useEffect } from 'react';
import { Select, Input, Button, Form } from 'antd';

const PatientForm = ({
  isNew,
  isWizard,
  data,
  onSubmit,
  branches,
  studies,
  qoolections,
  companyPrefix,
  createProcess = false,
}) => {
  const [form] = Form.useForm();

  let initialValues = data ? { ...data, deviceId: data.deviceId.substring(3) } : { ...data };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => form.resetFields(), []);
  const onFinish = async (values, isNew) => onSubmit(values, isNew);

  return (
    <div className='panel'>
      <div className={!createProcess ? 'modal-team' : undefined}>
        <Form
          initialValues={initialValues}
          onFinish={(values) => onFinish({ ...values, deviceId: companyPrefix + values.deviceId }, !data)}
          layout='vertical'
          form={form}
        >
          {!createProcess && (
            <div className='modal-team__header'>
              <h2>{isNew ? 'Add new' : 'Edit'} Patient</h2>
            </div>
          )}
          <div className='modal-team__input' style={isNew && { width: '50%' }}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: 'Please enter User Id!. Minimum 6 characters!',
                  min: 3,
                },
                {
                  validator: (_, value) => {
                    const fullUserId = companyPrefix + value;
                    if (fullUserId.length === 0 || fullUserId.substring(0, 3) === companyPrefix)
                      return Promise.resolve();
                    return Promise.reject(`User ID must be unique, and start with company prefix ${companyPrefix}`);
                  },
                },
              ]}
              name='deviceId'
            >
              <Input prefix={companyPrefix} placeholder='User ID' />
            </Form.Item>

            <Form.Item name='site' rules={[{ required: true, message: 'Please select site patient is assigned to!' }]}>
              <Select allowClear placeholder='Site' className='conditions-select dropdown-small' showArrow>
                {branches &&
                  branches.map((branch) => (
                    <Select.Option key={branch._id} value={branch._id}>
                      {branch.branchName}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
            <Form.Item
              name='study'
              rules={[{ required: true, message: 'Please select study patient is participating in!' }]}
            >
              <Select allowClear placeholder='Study' className='conditions-select dropdown-small' showArrow>
                {studies &&
                  studies.map((study) => (
                    <Select.Option key={study._id} value={study._id}>
                      {study.name}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
            {!isWizard && (
              <Form.Item label='Qoolection' name='qoolection'>
                <Select allowClear>
                  {qoolections &&
                    qoolections.map((q) => (
                      <Select.Option key={q._id} value={q._id}>
                        {q.title}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            )}
          </div>
          <Button
            id={'company-settings_add-patient'}
            type='primary'
            htmlType='submit'
            style={{ marginTop: '36px', width: !isNew ? '100%' : '165px' }}
          >
            {isNew ? 'Add' : 'Edit'} Patient
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default PatientForm;

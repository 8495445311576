/* eslint-disable array-callback-return */
import React, { useContext } from 'react';
import { UserContext } from '../../App';
import { Route, Switch, Redirect } from 'react-router-dom';
import { ROUTES } from '../../config';
import ProtectedRoute from './ProtectedRoute';
import Login from '../../pages/Login';
import Home from '../../pages/Home';
import ForgotPassword from '../../pages/ForgotPassword';
import CompanySettings from '../../pages/company-settings/CompanySettingsBuilder';

const Routes = () => {
  const user = useContext(UserContext);

  return (
    <Switch>
      <ProtectedRoute user={user.data} exact path='/' component={Home} allowed={['superadmin', 'admin']} />
      <ProtectedRoute user={user.data} exact path='/company-settings' component={CompanySettings} allowed={['admin']} />
      {/* routes from routeConfig file */}
      {ROUTES.map((route) => {
        if (route.children) {
          return route.children.map((item, i) => (
            <ProtectedRoute
              exact
              user={user.data}
              key={`R_${i}`}
              path={item.path}
              component={item.component}
              allowed={item.allowed}
            />
          ));
        }
      })}
      <Route exact path='/login' render={() => (user && user.isLoggedIn ? <Redirect to='/' /> : <Login />)} />
      <Route exact path='/admin/forgot-password' render={() => <ForgotPassword />} />
      <Route render={() => <Redirect to='/' />} />
    </Switch>
  );
};

export default Routes;

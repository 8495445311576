import React, { useContext, useEffect, useState } from 'react';
import HeaderComponent from '../../components/base/HeaderComponent';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import Axios from 'axios';
import { useHistory } from 'react-router-dom';
import { SERVER_URL } from '../../config';
import { notification, Layout, Button, Input, Modal } from 'antd';
import Table from '../../components/tables/ClientUsersTable';
import { ReactComponent as Magnifying } from '../../icons/magnifying-glas.svg';
import { LoadingOutlined } from '@ant-design/icons';
import ClientUserForm from '../../components/forms/ClientUserForm';

const ClientUsers = (props) => {

  const currentuser = useContext(UserContext);
  const [useri, fetchUseri] = useAxios('', [], currentuser.data.token, 'get');
  const history = useHistory();
  const [branchData, fetchBranchData] = useAxios('', [], currentuser.data.token, 'get');
  const [teamsData, fetchTeamsData] = useAxios('', [], currentuser.data.token, 'get');
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    fetchBranchData(`${SERVER_URL}/client-branches`, []);
    fetchTeamsData(`${SERVER_URL}/teams`, []);
    fetchUseri(`${SERVER_URL}/client-users`, {});
  }, [fetchBranchData, fetchTeamsData, fetchUseri]);

  const deleteDataHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/users/${id}`, { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` } });
      notification.success({
        message: 'User is deleted.',
        placement: 'bottomRight',
      });
    } catch (error) {
      notification.error({
        message: error?.response?.data?.message || 'Problem with delete. Please try later.',
        placement: 'bottomRight',
      });
    }
  };

  const addOrUpdateUserHandler = async (data, isNew) => {
    const method = isNew ? 'post' : 'put';
    const route = isNew ? `${SERVER_URL}/register` : `${SERVER_URL}/users/${userId}`;
    try {
      await Axios[method](route, { ...data, client: currentuser?.data.client }, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: 'Item is updated.',
        placement: 'bottomRight',
      });
      history.push('/');
      history.push('/client-users');
    } catch (error) {
      notification.error({
        message: error?.response?.data?.message || 'Problem with add/update. Please try later.',
        placement: 'bottomRight',
      });
    }
  };

  const { id } = props.match.params;
  const isDataFetched = !useri.isLoading && useri.data && currentuser.language;

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <Layout className='manage-layout'>
      <div
        style={{ textAlign: 'center', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}
      >
        <HeaderComponent text='Manage Users' />
        <div className="users-content">
          <div className="users-container">
            <div className='users-upper-part'>

              <Input
                className='inppy'
                placeholder='Search'
                onChange={(e) => fetchUseri(`${SERVER_URL}/client-users?search=${e.target.value}`, [])}
                prefix={<Magnifying style={{ marginRight: '5px' }} />} />
              <Button className='butty' type="primary" onClick={showModal}>Add new user</Button>
              <Button className='butty'>Bulk upload</Button>

            </div>

            <div className='users-table' style={{ textAlign: 'center', backgroundColor: 'white' }}>
              {useri.isLoading && <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />}
              {!useri.isLoading && useri.data && useri.data.items && useri.data.items.length > 0 && (
                <Table
                  data={useri?.data?.items}
                  deleteHandler={deleteDataHandler}
                  addOrUpdateUserHandler={addOrUpdateUserHandler}
                  setUserId={setUserId}
                  title='Users'
                  teams={teamsData && teamsData.data && teamsData.data.items}
                  branchs={branchData && branchData.data && branchData.data.items}
                />
              )}
              {!useri.isLoading && useri.data && useri.data.items && useri.data.items.length === 0 && <h2>NO DATA</h2>}
            </div>
          </div>
        </div>
        <Modal
          // title="Add new team" 
          open={isModalVisible}
          // onOk={handleOk} 
          onCancel={handleCancel}
          footer={null}
          centered={true}
        >
          <div style={{ padding: '8px' }}>

            <div>
              {!id && isDataFetched &&
                <ClientUserForm
                  isNew={true}
                  branchs={branchData && branchData.data && branchData.data.items}
                  teams={teamsData && teamsData.data && teamsData.data.items}
                  language={currentuser.language}
                  createHandler={addOrUpdateUserHandler}
                  SERVER_URL={SERVER_URL}
                  token={currentuser.data.token}
                />}
              {id && !useri.isError && useri.data && isDataFetched && (
                <ClientUserForm
                  isNew={false}
                  branchs={branchData && branchData.data && branchData.data.items}
                  teams={teamsData && teamsData.data && teamsData.data.items}
                  data={useri.data}
                  language={currentuser.language}
                  updateHandler={addOrUpdateUserHandler}
                  SERVER_URL={SERVER_URL}
                  token={currentuser.data.token}
                />
              )}
            </div>
          </div>
        </Modal>
      </div>
    </Layout>
  );
};

export default ClientUsers;
import React, { useEffect } from 'react';
import { Tabs, Divider, Input, Button, Form, Row, Col } from 'antd';

const { TextArea } = Input;

const formInit = {
  _id: null,
  name: '',
  description: '',
};

const CategoryForm = ({ isNew, data, onSubmit, language, SERVER_URL, token }) => {
  const [form] = Form.useForm();
  let initialValues = { ...formInit, ...data };
  useEffect(() => form.resetFields());
  const onFinish = async (values, isNew) => onSubmit(values, isNew);

  return (
    <>
      <Form
        className='form-horizontal'
        initialValues={initialValues}
        onFinish={(values) => onFinish(values, !data)}
        layout='vertical'
        form={form}
      >
        <Row type='flex' gutter={16}>
          <Col>
            <Form.Item rules={[{ required: true, message: 'Please enter name!' }]} name='name'>
              <Input placeholder='Name' />
            </Form.Item>
            <Form.Item name='description'>
              <TextArea placeholder='Description' rows={6} />
            </Form.Item>
          </Col>
        </Row>

        <div className='text-right'>
          <Button type='primary' htmlType='submit'>
            {isNew ? 'Add' : 'Edit'} Category
          </Button>
        </div>
      </Form>
    </>
  );
};

export default CategoryForm;

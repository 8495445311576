import React from 'react';
import { Link } from 'react-router-dom';
import { Form, Input, Button, Card, notification } from 'antd';
import { SERVER_URL } from '../config';
import Axios from 'axios';
import AuthMessage from '../components/elements/AuthMessage';
import Footer from '../components/base/Footer';
import { useState } from 'react';

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};
const tailLayout = {
  wrapperCol: { span: 24 },
};

const ForgotPassword = () => {
  const [forgotPasswordMessage, setForgotPasswordMessage] = useState('');

  const onFinish = async (values) => {
    try {
      await Axios.post(`${SERVER_URL}/forgot-password`, values);
      // notification.success({ message: 'Reset password link is sent to your email', position: 'topRight' });
      setForgotPasswordMessage({
        message: 'Reset password link is sent to your email',
        type: 'success',
      });
    } catch (error) {
      setForgotPasswordMessage({ message: error?.response?.data?.message, type: 'error' });
      // notification.error({ message: error?.response?.data?.message, position: 'topRight' });
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div className='log'>
      <div className='card-wrapper'>
        <Card title='Forgot your password?' className='login-header' bordered={false}>
          {forgotPasswordMessage && <AuthMessage content={forgotPasswordMessage} />}
          <Form {...layout} name='basic' onFinish={onFinish} onFinishFailed={onFinishFailed}>
            <Form.Item name='email' rules={[{ required: true, message: 'Please enter your email!' }]}>
              <Input placeholder='Email' />
            </Form.Item>

            <Form.Item {...tailLayout} style={{ marginBottom: 0 }}>
              <Button className='login-button' type='primary' htmlType='submit'>
                SEND EMAIL
              </Button>
            </Form.Item>
          </Form>
          <div className='back-to-login'>
            <Link to='/login'>Back to Login</Link>
          </div>
        </Card>
      </div>
      <Footer />
    </div>
  );
};

export default ForgotPassword;

import React, { useState } from 'react';
import { LoadingOutlined, EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Popconfirm, Modal } from 'antd';
import SortableTree, { toggleExpandedForAll } from 'react-sortable-tree';
import ClientBranchForm from '../../components/forms/ClientBranchForm';
import 'react-sortable-tree/style.css';
import { ReactComponent as PlusIcon } from '../../icons/add-icon.svg';
import { ReactComponent as DeleteIcon } from '../../icons/delete-icon.svg';
import { ReactComponent as EditIcon } from '../../icons/edit-icon.svg';

const ClientBranches = ({
  treeData,
  setTreeData,
  treeDataChangeHandler,
  treeBranchMoveHandler,
  treeDeleteDataHandler,
  client,
}) => {
  const [edit, setEdit] = useState({ visible: false, branch: {}, parent: {}, isNew: false, level: {} });

  const editHandler = (branch) => {
    const e = {
      visible: true,
      branch: branch,
      isNew: false,
    };
    setEdit(e);
  };

  const addNewHandler = (parent) => {
    const e = {
      visible: true,
      parent: parent._id,
      level: parent.level + 1,
      isNew: true,
    };
    setEdit(e);
  };

  const handleCancel = () => {
    setEdit({ visible: false, branch: {}, parent: {}, isNew: false, level: {} });
  };

  return (
    <>
      <div style={{ paddingLeft: 30, minHeight: '100%', display: 'flex' }}>
        <div style={{ flex: 'auto' }}>
          {treeData && treeData.length === 0 && (
            <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />
          )}

          {treeData.length > 0 && (
            <SortableTree
              style={{ minHeight: '100%' }}
              canDrag={true}
              treeData={treeData ? toggleExpandedForAll({ treeData, expanded: true }) : []}
              onChange={(data) => setTreeData(data)}
              onMoveNode={(data) => treeBranchMoveHandler(data)}
              canDrop={({ nextParent, nextPath }) => nextParent && nextPath.length < 4}
              generateNodeProps={(rowInfo, path) => ({
                buttons: [
                  <button
                    hidden={rowInfo.node.level >= client.organisationLevels}
                    type='button'
                    className='custom-button'
                    key={rowInfo.node._id + 'add'}
                    title='Add'
                    onClick={() => addNewHandler(rowInfo.node)}
                  >
                    <PlusIcon />
                  </button>,
                  <Popconfirm
                    className='custom-button'
                    placement='left'
                    title={`This will delete ${rowInfo.node.title}`}
                    onConfirm={() => {
                      treeDeleteDataHandler(rowInfo.node._id);
                    }}
                    okText='Ok'
                    cancelText='Cancel'
                  >
                    {rowInfo.node.level !== 1 && <DeleteIcon />}
                  </Popconfirm>,
                  <button
                    type='button'
                    className='custom-button'
                    key={rowInfo.node._id + 'edit'}
                    title='Edit'
                    onClick={() => editHandler(rowInfo.node)}
                  >
                    <EditIcon />
                  </button>,
                ],
              })}
            />
          )}
        </div>
      </div>

      <Modal style={{ maxWidth: '30vw' }} open={edit.visible} onCancel={handleCancel} footer={null} centered={true}>
        <ClientBranchForm
          style={{ height: '100%' }}
          onSubmit={treeDataChangeHandler}
          branch={edit.branch || {}}
          parent={edit.parent || {}}
          level={edit.level || {}}
          isNew={edit.isNew || false}
          isMaster={edit.isMaster || false}
          setEdit={setEdit}
        />
      </Modal>
    </>
  );
};

export default ClientBranches;

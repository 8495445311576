
import React, { useContext, useEffect } from 'react';
import { UserContext } from '../../App';
import Axios from 'axios';
import { useHistory } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { notification, Layout, Input } from 'antd';
import HeaderComponent from '../../components/base/HeaderComponent';
import useAxios from '../../hooks/useAxios';
import Table from '../../components/tables/Table';
import { ReactComponent as Magnifying } from '../../icons/magnifying-glas.svg';
import { SERVER_URL } from '../../config';

const TABLE_COLUMN_KEYS = [
  '_id',
  '__v',
  'createdAt',
  'updatedAt',
];

const TherapeuticArea = () => {
  const currentuser = useContext(UserContext);
  const [data, fetchData] = useAxios('', [], currentuser.data.token, 'get');
  const history = useHistory();

  useEffect(() => {
    fetchData(`${SERVER_URL}/therapeutic-area`, []);
  }, [fetchData]);

  const deleteDataHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/therapeutic-area/${id}`, { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` } });
      notification.success({
        message: 'Item is deleted.',
        placement: 'bottomRight',
      });
      history.push('/');
      history.push('/therapeutic-area');
    } catch (err) {
      notification.error({
        message: 'Problem with delete. Please try later.',
        placement: 'bottomRight',
      });
    }
  };

  let columnKeys;
  if (data.data && data.data.items && data.data.items.length > 0) {
    const keys = Object.keys(data.data.items[0]);
    columnKeys = keys.filter((k) => !TABLE_COLUMN_KEYS.includes(k));
  }

  let tableData = [];
  if (data.data && data.data.items && data.data.items.length > 0) {
    tableData = data.data.items.map((item) => {
      return item;
    });
  }

  return (
    <Layout className='manage-layout'>
      <div
        style={{ textAlign: 'center', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}
      >
        <HeaderComponent text='Therapeutic area and conditions' />
        <div className="statuses-content">
          <div className="statuses-container">

            <div className='statuses-upper-part'>
              <Input
                className='inppy'
                placeholder='Search'
                onChange={(e) => fetchData(`${SERVER_URL}/therapeutic-area?filter=${JSON.stringify({ name: { '$regex': e.target.value, '$options': 'i' } })}`, [])}
                prefix={<Magnifying style={{ marginRight: '5px' }} />} />
            </div>

            <div className='statuses-table' style={{ textAlign: 'center', backgroundColor: 'white' }}>
              {data.isLoading && <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />}
              {!data.isLoading && data.data && data.data.items && data.data.items.length > 0 && (
                <Table data={tableData} deleteHandler={deleteDataHandler} columnKeys={columnKeys} title='Therapeutic area' editPath='/edit-therapeutic-area/' actions='delete' />
              )}
              {!data.isLoading && data.data && data.data.items && data.data.items.length === 0 && <h2>NO DATA</h2>}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default TherapeuticArea;
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Card, Select, Result } from 'antd';
import { GalleryModal } from '.';
import useAxios from '../../hooks/useAxios';

// const tailLayout = {
//   wrapperCol: { span: 24 },
// };

// const layout = {
//   labelCol: { span: 4 },
//   wrapperCol: { span: 20 },
// };

const initialValues = {
  email: undefined,
  phone: undefined,
  client: undefined,
  firstName: undefined,
  lastName: undefined,
  jobFunction: 'IT / Data / Tools',
  role: ['admin'],
};

const AdminForm = ({ data, updateHandler, createHandler, result, setResult, SERVER_URL, token }) => {
  const [form] = Form.useForm();
  const [modal, setModal] = useState({ visible: false, formKey: null, limit: 1 });
  const [client, fetchSelectedClient] = useAxios('', {}, token, 'get');
  const [clients, fetchClients] = useAxios('', [], token, 'get');
  let user = data ? { ...data } : initialValues;
  const isNew = data ? false : true;

  // useEffect(() => {
  //   form.resetFields();
  // });

  useEffect(() => {
    form.resetFields();
    if (data && data.client) {
      fetchSelectedClient(`${SERVER_URL}/clients/${data.client}`, {});
    }
    fetchClients(`${SERVER_URL}/clients`, []);
  }, []);

  const onFinish = (values) => {
    if (isNew) createHandler(values);
    else updateHandler(values);
  };

  const onFinishFailed = (errorInfo) => console.log('Failed:', errorInfo);

  const onInsert = (values, formKey) => form.setFieldsValue({ [formKey]: values });

  return (
    <>
      {/* eslint-disable-next-line no-useless-concat */}
      {!result && (
        <Form
          // {...layout}
          name='basic'
          initialValues={user}
          onFinish={(values) => onFinish(values, isNew)}
          onFinishFailed={onFinishFailed}
          form={form}
          layout='vertical'
        >
          <Form.Item
            name='client'
            rules={[
              {
                required: true,
                message: 'Please select block type!',
              },
            ]}
          >
            <Select
              placeholder='Client'
              onChange={(value) => {
                fetchSelectedClient(`${SERVER_URL}/clients/${value}`, {});
              }}
            >
              {clients &&
                clients.data &&
                clients.data.items &&
                clients.data.items.length > 0 &&
                clients.data.items.map((client) => (
                  <Select.Option value={client._id} key={client._id}>
                    {client.companyName}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>

          <Form.Item
            name='email'
            rules={[
              {
                required: true,
                message: 'Please enter email!',
              },
            ]}
          >
            <Input placeholder='Email' />
          </Form.Item>

          <Form.Item name='phone'>
            <Input placeholder='Phone' />
          </Form.Item>

          <Form.Item
            name='firstName'
            rules={[
              {
                required: true,
                message: 'Please enter first name!',
              },
            ]}
          >
            <Input placeholder='First name' />
          </Form.Item>

          <Form.Item
            name='lastName'
            rules={[
              {
                required: true,
                message: 'Please enter last name!',
              },
            ]}
          >
            <Input placeholder='Last name' />
          </Form.Item>

          <Form.Item name='jobFunction'>
            <Select placeholder='Department'>
              <Select.Option value='IT / Data / Tools'>IT / Data / Tools</Select.Option>
              <Select.Option value='Executive'>Executive</Select.Option>
              <Select.Option value='Medical Affairs'>Medical Affairs</Select.Option>
              <Select.Option value='Sales / Commercial'>Sales / Commercial</Select.Option>
              <Select.Option value='Market Access'>Market Access</Select.Option>
              <Select.Option value='Communications'>Communications</Select.Option>
              <Select.Option value='Clinical'>Clinical</Select.Option>
              <Select.Option value='Other'>Other</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            hidden
            name='role'
            rules={[
              {
                required: true,
                message: 'Please select role!',
              },
            ]}
          >
            <Select mode='multiple' placeholder='Role'>
              <Select.Option value='admin'>Admin</Select.Option>
            </Select>
          </Form.Item>

          {!isNew && (
            <Form.Item
              name='status'
              rules={[
                {
                  required: true,
                  message: 'Please select status!',
                },
              ]}
            >
              <Select placeholder='Status'>
                <Select.Option value='ACTIVE'>ACTIVE</Select.Option>
                <Select.Option value='SUSPENDED'>SUSPENDED</Select.Option>
                <Select.Option value='DELETED'>DELETED</Select.Option>
                <Select.Option value='WAITING_FOR_ACTIVATION'>WAITING_FOR_ACTIVATION</Select.Option>
              </Select>
            </Form.Item>
          )}

          <Form.Item
          // {...tailLayout}
          >
            <Button type='primary' htmlType='submit' style={{ float: 'right' }}>
              {isNew ? 'Add' : 'Edit'} Admin
            </Button>
          </Form.Item>
        </Form>
      )}
      {result && (
        <Result
          title='User has been created and verification mail has been sent'
          extra={
            <Button type='primary' key='console' onClick={() => setResult(false)}>
              Ok
            </Button>
          }
        />
      )}

      {modal.visible && (
        <GalleryModal
          open={modal.visible}
          limit={modal.limit}
          formKey={modal.formKey}
          imageSavePath='public/images/users/'
          imageType='data'
          imageHeight={200}
          SERVER_URL={SERVER_URL}
          token={token}
          form={form}
          onCancel={() => setModal({ ...modal, visible: false })}
          onInsert={(values) => onInsert(values, modal.formKey)}
        />
      )}
    </>
  );
};

export default AdminForm;

import React, { useContext, useState, useEffect } from 'react';
import { Table, Popconfirm, Modal, Tag, Checkbox } from 'antd';
import { UserContext } from '../../App';
import { ReactComponent as Trash } from '../../icons/ph_trash.svg';
import { ReactComponent as Edit } from '../../icons/ph_note-pencil-light.svg';
import 'antd/dist/antd.css';
import ClientUserForm from '../../components/forms/ClientUserForm';
import { SERVER_URL } from '../../config';
import useAxios from '../../hooks/useAxios';

const ClientUsersTable = ({ data, deleteHandler, addOrUpdateUserHandler, title, branchs, teams, setUserId }) => {
  const user = useContext(UserContext);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [id, setId] = useState();
  const currentuser = useContext(UserContext);
  const [usersData, fetchUsersData] = useAxios('', {}, currentuser.data.token, 'get');

  const showModal = () => {
    setIsModalVisible(true);
  };

  useEffect(() => {
    if (id) fetchUsersData(`${SERVER_URL}/users/${id}`, {});
  }, [fetchUsersData, id]);

  const columns = [
    {
      title: 'First Name',
      dataIndex: 'firstName',
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
    },
    {
      title: 'Department',
      dataIndex: 'jobFunction',
    },
    {
      title: 'Role',
      dataIndex: 'role',
      render: (text, record) =>
        record.role.map((role, index) => (
          <Tag style={{ backgroundColor: '#b3f5e1' }} key={role + index}>
            {role}
          </Tag>
        )),
    },
    {
      title: 'Team',
      dataIndex: 'team',
      render: (text, record) =>
        record.team.map((team) => (
          <Tag style={{ backgroundColor: '#e483f7' }} key={team._id}>
            {team.name}
          </Tag>
        )),
    },
    {
      title: 'Branch',
      dataIndex: ['clientBranch', 'branchName'],
    },
    {
      title: 'License',
      dataIndex: 'licenceActive',
      render: (text, record) => <Checkbox disabled checked={record.licenceActive ? true : false} />,
    },
    {
      title: 'Action',
      render: (text, record) => (
        <div
          style={{
            display: 'inline-flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '15px',
            color: '#90989E',
          }}
        >
          <div style={{ margin: '2px', padding: '2px', cursor: 'pointer' }}>
            <Popconfirm
              disabled={record._id === user.data.id}
              placement='left'
              title={`This will delete ${title.toLowerCase()}`}
              onConfirm={() => {
                deleteHandler(record._id);
              }}
              okText='Ok'
              cancelText='Cancel'
            >
              <Trash title={`Delete user`} />
            </Popconfirm>
          </div>

          <div style={{ margin: '2px', padding: '2px', cursor: 'pointer' }}>
            <Edit
              onClick={() => {
                showModal();
                setId(record._id);
                setUserId(record._id);
              }}
              title={`Edit team`}
              style={{ textDecoration: 'none' }}
            />
          </div>
        </div>
      ),
    },
  ];

  const isDataFetched = !user.isLoading && user.data && currentuser.language;

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <div className='table-manage'>
        <Table
          size='middle'
          bordered
          dataSource={data}
          columns={columns}
          rowKey='_id'
          pagination={{
            defaultPageSize: 20,
            position: 'bottom',
            showSizeChanger: false,
            pageSizeOptions: ['10', '20', '50', '100'],
            hideOnSinglePage: true,
          }}
        />
      </div>
      <Modal open={isModalVisible} onCancel={handleCancel} footer={null} centered={true}>
        <div style={{ padding: '8px' }}>
          <div>
            {id && !usersData.isError && usersData.data && isDataFetched && (
              <ClientUserForm
                isNew={false}
                data={usersData.data}
                language={currentuser.language}
                updateHandler={addOrUpdateUserHandler}
                SERVER_URL={SERVER_URL}
                token={currentuser.data.token}
                teams={teams}
                branchs={branchs}
              />
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ClientUsersTable;

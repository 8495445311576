/* eslint-disable default-case */
import React, { useEffect } from 'react';
import { Select, Input, Button, Form, Row, Col } from 'antd';

const AIQbuilderForm = ({ isNew, data, onSubmit, qoolections }) => {
  const formInit = isNew ? {
    params: Array.from(Array(18), () => (
      {
        item: '',
        label: '',
        modifier: '',
        prompt: '',
      }))
  }
    : {};
  const [form] = Form.useForm();
  let initialValues = { ...formInit, ...data };

  useEffect(() => {
    form.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFinish = async (values, isNew) => onSubmit(values, isNew);

  return (
    <div className='panel'>
      <div className={!isNew ? 'modal-team' : undefined}>
        <Form initialValues={initialValues}
          onFinish={(values) => onFinish(values, !data)} layout='vertical' form={form}>
          {!isNew && (
            <div className='modal-team__header'>
              <h2>{isNew ? 'Add new' : 'Update'} AIQBuilder</h2>
            </div>
          )}

          <div className='modal-team__input'>
            <Row gutter={4} >
              <Col span={4}>
                <Form.Item name='temperature'>
                  <Input addonBefore='Temperature' />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item name='title'>
                  <Input addonBefore='Title' />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item name='max_tokens'>
                  <Input addonBefore='Max tokens' />
                </Form.Item>
              </Col>
            </Row>

            {initialValues.params && initialValues.params.map((param, index) => (
              <Row gutter={4} key={index}>
                <Col span={6}>
                  <Form.Item name={['params', index, 'label']}>
                    <Input addonBefore='Label' />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name={['params', index, 'item']}>
                    <Input addonBefore='Item' />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name={['params', index, 'modifier']}>
                    <Input addonBefore='Modifier' />
                  </Form.Item>
                </Col>
              </Row>
            ))}
            <Form.Item label="Qoolection" name="qoolection" >
              <Select allowClear>
                {qoolections && qoolections.map(q => (<Select.Option key={q._id} value={q._id}>{q.title}</Select.Option>))}
              </Select>
            </Form.Item>

          </div>
          <Button type='primary' htmlType='submit' style={{ float: 'left', margin: '15px 0px' }}>
            {isNew ? 'Add' : 'Update'} AIQBuilder
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default AIQbuilderForm;

import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import { ROUTES } from '../../config';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
// import { ReactComponent as LogoBig } from '../../icons/logo-big.svg';
import { ReactComponent as LogoSmall } from '../../icons/logo-small.svg';
import { ReactComponent as LogoBig } from '../../icons/LogoQC.svg';
import { UserContext } from '../../App';

const { SubMenu } = Menu;
const { Sider } = Layout;

const NavMenu = (props) => {
  const { isCollapsed, setCollapsed, user } = props;
  const currentuser = useContext(UserContext);
  // console.log('currentuser: ', currentuser);

  console.log('client wizard', currentuser?.data?.client?.setupFinished);
  console.log('session wizard', sessionStorage.getItem('setupWizardFinished'));

  return (
    <Sider
      breakpoint='lg'
      className='main-sidebar'
      trigger={null}
      collapsible
      collapsed={isCollapsed}
      width={260}
      collapsedWidth={56}
      onBreakpoint={() => setCollapsed(false)}
    >
      <div className='main-sidebar__logo'>
        {isCollapsed ? (
          <>
            <LogoSmall className='main-sidebar__logo-icon-small' onClick={() => setCollapsed(false)} />
          </>
        ) : (
          <>
            <LogoBig />
            <LeftOutlined className='main-sidebar__collapse-icon' onClick={() => setCollapsed(true)} />
          </>
        )}
      </div>
      <Menu
        mode='inline'
        defaultSelectedKeys={[window.location.pathname]}
        style={{ backgroundColor: 'transparent' }}
        expandIcon={<RightOutlined />}
      >
        {user &&
          user.data &&
          ROUTES.filter((route) => route.showInMenu.some((item) => user.data.role.some((r) => r === item))).map(
            (route) => {
              if (route.label === 'Company Settings') {
                if (currentuser.data.client.setupFinished || sessionStorage.getItem('setupWizardFinished')) {
                  return null;
                } else {
                  return (
                    <Menu.Item
                      key={route.label.toLowerCase()}
                      icon={<route.icon />}
                      id={route.label.replace(/\s/g, '_').trim().toLowerCase()}
                    >
                      <Link to={route.path}>Admin Wizard</Link>
                    </Menu.Item>
                  );
                }
              }
              if (!route.children && route.path) {
                return (
                  <Menu.Item
                    key={route.label.toLowerCase()}
                    icon={<route.icon />}
                    id={route.label.replace(/\s/g, '_').trim().toLowerCase()}
                  >
                    <Link to={route.path}>{route.label}</Link>
                  </Menu.Item>
                );
              } else {
                return (
                  <SubMenu
                    key={route.label.toLowerCase()}
                    title={route.label}
                    icon={<route.icon />}
                    id={route.label.replace(/\s/g, '_').trim().toLowerCase()}
                  >
                    {route.children
                      .filter((item) => item.showInMenu)
                      .map((item) => {
                        return (
                          <Menu.Item
                            key={item.label}
                            icon={item.icon}
                            id={`${route.label.replace(/\s/g, '_').trim().toLowerCase()}-${item.label
                              .trim()
                              .toLowerCase()}`}
                          >
                            <Link to={item.path}>{item.label}</Link>
                          </Menu.Item>
                        );
                      })}
                  </SubMenu>
                );
              }
            },
          )}
      </Menu>
    </Sider>
  );
};
export default NavMenu;

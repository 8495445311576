import React, { useEffect } from 'react';
import { Select, Input, Button, Form, Row, Col } from 'antd';

const PersonnelForm = ({
  isNew,
  isWizard,
  data,
  onSubmit,
  branches,
  qoolections,
  companyPrefix,
  createProcess = false,
}) => {
  const [form] = Form.useForm();
  let initialValues = data ? { ...data, deviceId: data.deviceId.substring(3) } : { ...data };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => form.resetFields(), []);
  const onFinish = async (values, isNew) => onSubmit(values, isNew);

  return (
    <div className='panel'>
      <div className={!createProcess ? 'modal-team' : undefined}>
        <Form
          initialValues={initialValues}
          onFinish={(values) => onFinish({ ...values, deviceId: companyPrefix + values.deviceId }, !data)}
          layout='vertical'
          form={form}
        >
          {!createProcess && (
            <div className='modal-team__header'>
              <h2>{isNew ? 'Add new' : 'Edit'} Personnel</h2>
            </div>
          )}
          <div className='modal-team__input'>
            <Row gutter={8}>
              <Col span={12}>
                <Form.Item name='firstName' rules={[{ required: true, message: 'Please enter first name!' }]}>
                  <Input placeholder='First Name' />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name='lastName' rules={[{ required: true, message: 'Please enter last name!' }]}>
                  <Input placeholder='Last Name' />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={8}>
              <Col span={12}>
                <Form.Item
                  name='email'
                  rules={[
                    { required: true, message: 'Please enter email!' },
                    { type: 'email', message: 'Please enter valid email!' },
                  ]}
                >
                  <Input placeholder='Email' />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name='phone'>
                  <Input placeholder='Phone' />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={8}>
              <Col span={12}>
                <Form.Item
                  name='site'
                  rules={[{ required: true, message: 'Please select site personnel is assigned to!' }]}
                >
                  <Select allowClear placeholder='Site' className='conditions-select dropdown-small' showArrow>
                    {branches &&
                      branches.map((branch) => (
                        <Select.Option key={branch._id} value={branch._id}>
                          {branch.branchName}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: 'Please enter User Id!. Minimum 6 characters!',
                      min: 3,
                    },
                    {
                      validator: (_, value) => {
                        const fullUserId = companyPrefix + value;
                        if (fullUserId.length === 0 || fullUserId.substring(0, 3) === companyPrefix)
                          return Promise.resolve();
                        return Promise.reject(`User ID must be unique, and start with company prefix ${companyPrefix}`);
                      },
                    },
                  ]}
                  name='deviceId'
                >
                  <Input prefix={companyPrefix} placeholder='User ID' />
                </Form.Item>
              </Col>
            </Row>

            {!isWizard && (
              <Form.Item name='qoolection'>
                <Select allowClear placeholder='Qoolection' className='conditions-select dropdown-small' showArrow>
                  {qoolections &&
                    qoolections.map((q) => (
                      <Select.Option key={q._id} value={q._id}>
                        {q.title}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            )}
          </div>
          <Button
            id={'company-settings_add-patient'}
            type='primary'
            htmlType='submit'
            style={{ marginTop: '36px', width: !isNew ? '100%' : '165px' }}
          >
            {isNew ? 'Add' : 'Edit'} Personnel
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default PersonnelForm;

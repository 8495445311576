import React, { useContext, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Axios from 'axios';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import { UserContext } from '../../App';
import { Button, Layout, notification } from 'antd';
import StatusForm from '../../components/forms/StatusForm';
import HeaderComponent from '../../components/base/HeaderComponent';

const EditStatus = (props) => {
  const currentuser = useContext(UserContext);
  const history = useHistory();
  const [item, fetchItem] = useAxios('', {}, currentuser.data.token, 'get');
  const { statusId } = props.match.params;

  useEffect(() => {
    if (statusId) fetchItem(`${SERVER_URL}/statuses/${statusId}`, {});
  }, [fetchItem, statusId]);

  const onSubmit = async (formData, isNew) => {
    const method = isNew ? 'post' : 'put';
    const route = isNew ? `${SERVER_URL}/statuses` : `${SERVER_URL}/statuses/${statusId}`;
    try {
      await Axios[method](route, formData, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      notification.success({
        message: `Status ${isNew ? 'created.' : 'updated.'}`,
        placement: 'bottomRight',
      });
      history.push('/');
      history.push('/statuses');
    } catch (error) {
      const msg = error.response ? error.response.data.message : error.message ? error.message : error;
      console.log(msg);
      notification.error({
        message: msg,
        placement: 'bottomRight',
      });
    }
  };

  const isDataFetched = !item.isLoading && item.data && currentuser.language;

  const [isNew, setIsNew] = useState(false);

  useEffect(() => {
    if ((item?.data && Object.keys(item?.data).length < 1) || !item?.data) {
      setIsNew(true);
    } else {
      setIsNew(false);
    }
  }, [item]);

  return (
    <Layout className='manage-layout'>
      <div
        style={{ textAlign: 'center', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}
      >
        <HeaderComponent text={isNew ? 'Add new Status' : 'Edit Status'} />
        <div className='form-wrapper'>
          {!statusId && isDataFetched && (
            <StatusForm
              isNew={true}
              language={currentuser.language}
              onSubmit={onSubmit}
              SERVER_URL={SERVER_URL}
              token={currentuser.data.token}
            />
          )}
          {statusId && !item.isError && item.data && isDataFetched && (
            <StatusForm
              isNew={false}
              data={item.data}
              language={currentuser.language}
              onSubmit={onSubmit}
              SERVER_URL={SERVER_URL}
              token={currentuser.data.token}
            />
          )}
        </div>
      </div>
    </Layout>
  );
};

export default EditStatus;

import React, { useEffect } from 'react';
import { Input, Button, Form, Select, Col, Row } from 'antd';

const { Option } = Select;

const formInit = {
  _id: null,
  name: '',
};

const UserForm = ({ createHandler, updateHandler, isNew, branchs, data, teams }) => {
  const [form] = Form.useForm();
  if (data && data.clientBranch && data.clientBranch._id) {
    data.clientBranch = data.clientBranch._id;
  }
  let initialValues = { ...formInit, ...data };

  useEffect(() => form.resetFields());

  const onFinish = (values) => {
    if (isNew) createHandler(values);
    else updateHandler(values);
  };

  const onFinishFailed = (errorInfo) => console.log('Failed:', errorInfo);

  return (
    <div className='panel'>
      <div className='modal-team'>
        <Form initialValues={initialValues}
          onFinish={(values) => onFinish(values, !data)}
          onFinishFailed={onFinishFailed}
          layout='vertical'
          key={Math.random()}
          form={form}>
          <div className='modal-team__header'>
            <h2>{isNew ? 'Add new' : 'Update'} user</h2>
          </div>
          <div className='modal-team__input'>
            <Form.Item
              label='Email'
              rules={[{ required: true, message: 'Please enter your Email!' }]}
              name='email'
            >
              <Input />
            </Form.Item>
            <Form.Item>
              <Row gutter={8}>
                <Col span={12}>
                  <Form.Item
                    label="First name"
                    name="firstName"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your First name!',
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Last name"
                    name="lastName"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your Last name!',
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>
            <Form.Item
              name="role"
              label="Role"
              rules={[{ required: true }]} >
              <Select allowClear>
                <Option value="creator">Creator</Option>
                <Option value="publisher">Monitor</Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="team"
              label="Team"
              rules={[{ required: false }]} >
              <Select mode="multiple" allowClear>
                {teams && teams.map(team => (<Option value={team._id}>{team.name}</Option>))}
              </Select>
            </Form.Item>
            <Form.Item
              name="jobFunction"
              label="Department"
              rules={[{ required: false, },]} >
              <Select allowClear>
                <Option value="IT / Data / Tools">IT / Data / Tools</Option>
                <Option value="Executive">Executive</Option>
                <Option value="Medical Affairs">Medical Affairs</Option>
                <Option value="Sales / Commercial">Sales / Commercial</Option>
                <Option value="Market Access">Market Access</Option>
                <Option value="Communications">Communications</Option>
                <Option value="Clinical">Clinical</Option>
                <Option value="Other">Other</Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="clientBranch"
              label="Branch"
              rules={[{ required: false, },]} >
              <Select
                allowClear
              >
                {branchs && branchs.map(branch => (<Option value={branch._id}>{branch.branchName}</Option>))}
              </Select>
            </Form.Item>
          </div>
          <Button type='primary' htmlType='submit'
            className='margin-top-20'>
            {isNew ? 'Add' : 'Update'} user
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default UserForm;
import React, { useEffect, useContext } from 'react';
import Axios from 'axios';
import { Link } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { notification, Layout, Button, Input } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import AdminsTable from '../../components/tables/AdminsTable';
import HeaderComponent from '../../components/base/HeaderComponent';
import { ReactComponent as Magnifying } from '../../icons/magnifying-glas.svg';
import { SERVER_URL } from '../../config';

const Users = () => {
  const currentuser = useContext(UserContext);
  const [users, fetchUsers] = useAxios('', [], currentuser.data.token, 'get');

  useEffect(() => {
    fetchUsers(`${SERVER_URL}/client-admins`, []);
  }, [fetchUsers]);


  const deleteUserHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/users/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: 'Item is deleted.',
        placement: 'bottomRight',
      });
      setTimeout(() => {
        window.location.reload();
      }, 1200);
    } catch (error) {
      notification.error({
        message: error?.response?.data?.message || 'Problem with delete. Please try later.',
        placement: 'bottomRight',
      });
    };
  };

  return (
    <Layout className='manage-layout'>
      <div
        style={{ textAlign: 'center', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}
      >
        <HeaderComponent text='Admins' />
        <div className="admins-content">
          <div className="admins-container">

            <div className='admins-upper-part'>
              <Input
                className='inppy'
                placeholder='Search'
                onChange={(e) => fetchUsers(`${SERVER_URL}/client-admins?search=${e.target.value}`, [])}
                prefix={<Magnifying style={{ marginRight: '5px' }} />} />
              <Link to='/new-admin'>
                <Button className='butty' type="primary" >New admin</Button>
              </Link>
              <Button className='butty'>Bulk upload</Button>
            </div>

            <div className='admins-table' style={{ textAlign: 'center', backgroundColor: 'white' }}>
              {users.isLoading && (
                <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />
              )}
              {!users.isLoading && users.data.items && users.data.items.length > 0 && (
                <AdminsTable
                  data={users.data.items}
                  deleteHandler={deleteUserHandler}
                  title='Admin'
                  editPath='/edit-admin/'
                />
              )}
              {!users.isLoading && users.data.items && users.data.items.length === 0 && <h2>NO DATA</h2>}
            </div>
          </div>
        </div>
      </div>
    </Layout >
  );
};

export default Users;

import React, { useEffect } from 'react';
import { Input, Button, Form, Select, Col, Row, Checkbox, Switch } from 'antd';

const { Option } = Select;

const formInit = {
  _id: null,
};

const ClientUserForm = ({
  createHandler,
  updateHandler,
  isNew,
  branchs,
  data,
  teams,
  createProcess = false,
  availableCreatorLicences,
  availableMonitorLicences,
}) => {
  const [form] = Form.useForm();
  if (data && data.clientBranch && data.clientBranch._id) data.clientBranch = data.clientBranch._id;
  useEffect(() => form.resetFields());
  let initialValues = { ...formInit, ...data };
  const onFinish = (values) => {
    if (isNew) createHandler(values, true);
    else updateHandler(values, false);
  };
  const onRoleChange = (value) => form.setFieldsValue({ role: value });
  const onFinishFailed = (errorInfo) => console.log('Failed:', errorInfo);

  return (
    <div className='panel'>
      <div className={!createProcess ? 'modal-team' : undefined}>
        <Form
          initialValues={initialValues}
          onFinish={(values) => onFinish(values, !data)}
          onFinishFailed={onFinishFailed}
          // layout='vertical'
          key={Math.random()}
          form={form}
        >
          {!createProcess && (
            <div className='modal-team__header'>
              <h2>{isNew ? 'Add new' : 'Edit'} User</h2>
            </div>
          )}
          <div className='modal-team__input'>
            <Row gutter={8}>
              <Col span={12}>
                <Form.Item name='firstName' rules={[{ required: true, message: 'Please input your First name!' }]}>
                  <Input placeholder='First Name' />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name='lastName' rules={[{ required: true, message: 'Please input your Last name!' }]}>
                  <Input placeholder='Last Name' />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={8}>
              <Col span={12}>
                {isNew && (
                  <Form.Item rules={[{ required: true, message: 'Please enter your Email!' }]} name='email'>
                    <Input placeholder='Email' />
                  </Form.Item>
                )}
              </Col>
              <Col span={isNew ? 12 : 24}>
                <Form.Item name='phone'>
                  <Input placeholder='Phone' />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={8}>
              <Col span={12}>
                {isNew ? (
                  <Form.Item name='role' rules={[{ required: true }]}>
                    <Select onChange={onRoleChange} placeholder='Role' className='conditions-select dropdown-small'>
                      <Option value='creator'>Creator</Option>
                      <Option value='monitor'>Monitor</Option>
                    </Select>
                  </Form.Item>
                ) : (
                  <Form.Item name='role'>
                    <Select disabled>
                      <Option value='creator'>Creator</Option>
                      <Option value='monitor'>Monitor</Option>
                    </Select>
                  </Form.Item>
                )}
              </Col>
              <Col span={12}>
                <Form.Item name='team' rules={[{ required: false }]}>
                  <Select
                    mode='multiple'
                    allowClear
                    placeholder='Teams'
                    className='conditions-select dropdown-small'
                    maxTagCount={1}
                  >
                    {teams &&
                      teams.map((team) => (
                        <Option key={team._id} value={team._id}>
                          {team.name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={8}>
              <Col span={12}>
                <Form.Item name='jobFunction' rules={[{ required: false }]}>
                  <Select placeholder='Department' className='conditions-select dropdown-small'>
                    <Option value='IT / Data / Tools'>IT / Data / Tools</Option>
                    <Option value='Executive'>Executive</Option>
                    <Option value='Medical Affairs'>Medical Affairs</Option>
                    <Option value='Sales / Commercial'>Sales / Commercial</Option>
                    <Option value='Market Access'>Market Access</Option>
                    <Option value='Communications'>Communications</Option>
                    <Option value='Clinical'>Clinical</Option>
                    <Option value='Other'>Other</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name='clientBranch' rules={[{ required: false }]}>
                  <Select placeholder='Site' className='conditions-select dropdown-small'>
                    {branchs &&
                      branchs.map((branch) => (
                        <Option key={branch._id} value={branch._id}>
                          {branch.branchName}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) => prevValues.role !== currentValues.role}
              // shouldUpdate={true}
            >
              {({ getFieldValue }) => {
                const role = getFieldValue('role');
                if (role === 'creator' || (role && role[0] && role[0] === 'creator'))
                  return (
                    <Form.Item
                      name='licenceActive'
                      valuePropName='checked'
                      label={`${
                        getFieldValue('licenceActive') ? 'Assigned Creator License' : 'Assign Creator License'
                      } (available ${availableCreatorLicences})`}
                      style={{ textAlign: 'left' }}
                    >
                      <Switch disabled={availableCreatorLicences === 0} />
                    </Form.Item>
                  );
                if (role === 'agency' || (role && role[0] && role[0] === 'agency'))
                  return (
                    <Form.Item
                      name='licenceActive'
                      valuePropName='checked'
                      label={`${
                        getFieldValue('licenceActive') ? 'Assigned Creator License' : 'Assign Creator License'
                      } (available ${availableCreatorLicences})`}
                      style={{ textAlign: 'left' }}
                    >
                      <Switch disabled={availableCreatorLicences === 0} />
                    </Form.Item>
                  );
                if (role === 'monitor' || (role && role[0] && role[0] === 'monitor'))
                  return (
                    <Form.Item
                      name='licenceActive'
                      valuePropName='checked'
                      label={`${
                        getFieldValue('licenceActive') ? 'Assigned Monitor License' : 'Assign Monitor License'
                      } (available ${availableMonitorLicences})`}
                      style={{ textAlign: 'left' }}
                    >
                      <Switch disabled={availableMonitorLicences === 0} />
                    </Form.Item>
                  );
              }}
            </Form.Item>
          </div>
          <Button
            id={'company-settings_add-user'}
            type='primary'
            htmlType='submit'
            style={{ marginTop: '36px', width: !isNew ? '100%' : '165px' }}
          >
            {isNew ? 'Add' : 'Edit'} User
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default ClientUserForm;

import React, { useContext, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Axios from 'axios';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import { UserContext } from '../../App';
import { Button, Layout, notification } from 'antd';
import AudienceForm from '../../components/forms/AudienceForm';
import HeaderComponent from '../../components/base/HeaderComponent';

const EditAudience = (props) => {
  const currentuser = useContext(UserContext);
  const history = useHistory();
  const [audience, fetchAudience] = useAxios('', {}, currentuser.data.token, 'get');
  const { audienceId } = props.match.params;

  useEffect(() => {
    if (audienceId) fetchAudience(`${SERVER_URL}/audiences/${audienceId}`, {});
  }, [fetchAudience, audienceId]);

  const onSubmit = async (formData, isNew) => {
    const method = isNew ? 'post' : 'put';
    const route = isNew ? `${SERVER_URL}/audiences` : `${SERVER_URL}/audiences/${audienceId}`;
    try {
      await Axios[method](route, formData, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      notification.success({
        message: `Audience ${isNew ? 'created.' : 'updated.'}`,
        placement: 'bottomRight',
      });
      history.push('/');
      history.push('/audiences');
    } catch (error) {
      notification.error({
        message: error?.response?.data?.message || 'Problem with create/update.',
        placement: 'bottomRight',
      });
    }
  };

  const isDataFetched = !audience.isLoading && audience.data && currentuser.language;

  const [isNew, setIsNew] = useState(false);

  useEffect(() => {
    if ((audience?.data && Object.keys(audience?.data).length < 1) || !audience?.data) {
      setIsNew(true);
    } else {
      setIsNew(false);
    }
  }, [audience]);

  return (
    <Layout className='manage-layout'>
      <div
        style={{ textAlign: 'center', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}
      >
        <HeaderComponent text={isNew ? 'Add new Audience' : 'Edit Audience'} />
        <div className='form-wrapper'>
          {!audienceId && isDataFetched && (
            <AudienceForm
              isNew={true}
              language={currentuser.language}
              onSubmit={onSubmit}
              SERVER_URL={SERVER_URL}
              token={currentuser.data.token}
            />
          )}
          {audienceId && !audience.isError && audience.data && isDataFetched && (
            <AudienceForm
              isNew={false}
              data={audience.data}
              language={currentuser.language}
              onSubmit={onSubmit}
              SERVER_URL={SERVER_URL}
              token={currentuser.data.token}
            />
          )}
        </div>
      </div>
    </Layout>
  );
};

export default EditAudience;

/* eslint-disable default-case */
import Axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Select, Input, Button, Form, Row, Col } from 'antd';

const formInit = {
  _id: null,
  name: '',
  therapeuticArea: [],
  therapeuticAreaCondition: [],
};

const StudyForm = ({ isNew, data, onSubmit, SERVER_URL, createProcess = false }) => {
  const [form] = Form.useForm();
  let initialValues = { ...formInit, ...data };
  const [filteredConditions, setFilteredConditions] = useState([]);
  const [therapeuticArea, setTherapeuticArea] = useState([]);
  const [therapeuticAreaCondition, setTherapeuticAreaCondition] = useState([]);

  useEffect(() => {
    getTherapeuticsArea();
    getTherapeuticsAreaConditions();
    form.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFinish = async (values, isNew) => onSubmit(values, isNew);

  const onTherapeuticAreaStateChange = async (value) => {
    const filter = await therapeuticAreaCondition.filter((c) => value.includes(c.therapeuticArea));
    setFilteredConditions(filter);
  };

  const getTherapeuticsArea = async () => {
    try {
      const response = await Axios.get(`${SERVER_URL}/therapeutic-area-for-brands`);
      setTherapeuticArea(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getTherapeuticsAreaConditions = async () => {
    try {
      const response = await Axios.get(`${SERVER_URL}/therapeutic-area-condition-for-brands`);
      setTherapeuticAreaCondition(response?.data);
      setFilteredConditions(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className='panel'>
      <div className={!createProcess ? 'modal-team' : undefined}>
        <Form
          initialValues={initialValues}
          onFinish={(values) => onFinish(values, !data)}
          layout='vertical'
          form={form}
        >
          {!createProcess && (
            <div className='modal-team__header'>
              <h2>{isNew ? 'Add new' : 'Edit'} Study</h2>
            </div>
          )}
          <div className='modal-team__input'>
            <Row gutter={8}>
              <Col span={12}>
                <Form.Item rules={[{ required: true, message: 'Please enter study name!' }]} name='name'>
                  <Input placeholder='Study Name' />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name='therapeuticArea'
                  label=''
                  rules={[{ required: true, message: 'Please select at least one therapeutic area' }]}
                >
                  <Select
                    mode='multiple'
                    placeholder='Therapeutic Area'
                    allowClear
                    onChange={async (value) => await onTherapeuticAreaStateChange(value)}
                    className='dropdown-small'
                    showArrow
                    maxTagCount={3}
                  >
                    {therapeuticArea &&
                      therapeuticArea.map((t) => (
                        <Select.Option key={t._id} value={t._id}>
                          {t.name}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={8}>
              <Col span={12}>
                <Form.Item name='description'>
                  <Input.TextArea placeholder='Description' />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name='therapeuticAreaCondition'
                  rules={[{ required: true, message: 'Please select at least one condition' }]}
                >
                  <Select
                    mode='multiple'
                    placeholder='Conditions'
                    allowClear
                    showArrow
                    className='conditions-select dropdown-small'
                    maxTagCount={3}
                  >
                    {filteredConditions &&
                      filteredConditions.map((c) => (
                        <Select.Option key={c._id} value={c._id} id={c.name.toLowerCase().split(' ').join('_')}>
                          {c.name}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </div>
          <Button
            style={{ marginTop: '36px', width: !isNew ? '100%' : '165px' }}
            type='primary'
            htmlType='submit'
            id={'company-settings_add-study'}
          >
            {isNew ? 'Add' : 'Edit'} Study
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default StudyForm;

import React, { useContext, useState, useEffect } from 'react';
import { Table, Popconfirm, Modal, notification, Tag } from 'antd';
import { UserContext } from '../../App';
import 'antd/dist/antd.css';
import UserForm from '../forms/UserForm';
import { SERVER_URL } from '../../config';
import Axios from 'axios';
import { useHistory, Link } from 'react-router-dom';
import useAxios from '../../hooks/useAxios';
import { ReactComponent as Trash } from '../../icons/ph_trash.svg';
import { ReactComponent as Edit } from '../../icons/ph_note-pencil-light.svg';

const AdminsTable = ({ data, deleteHandler, columnKeys, title, editPath, branchs, teams }) => {
  const user = useContext(UserContext);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [id] = useState();
  const history = useHistory();
  const currentuser = useContext(UserContext);
  const [usersData, fetchUsersData] = useAxios('', {}, currentuser.data.token, 'get');

  useEffect(() => {
    if (id) fetchUsersData(`${SERVER_URL}/users/${id}`, {});
  }, [fetchUsersData, id]);

  const updateUser = async (data) => {
    try {
      await Axios.put(
        `${SERVER_URL}/users/${id}`,
        { ...data },
        { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` } },
      );
      notification.success({
        message: 'Item is updated.',
        placement: 'bottomRight',
      });
      history.push('/');
      history.push('/users');
    } catch (err) {
      notification.error({
        message: 'Problem with update. Please try later.',
        placement: 'bottomRight',
      });
    }
  };

  const columns = [
    {
      title: 'Company',
      dataIndex: ['client', 'companyName'],
    },
    {
      title: 'First Name',
      dataIndex: 'firstName',
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
    },
    {
      title: 'Department',
      dataIndex: 'jobFunction',
    },
    {
      title: 'Role',
      dataIndex: 'therapeuticArea',
      render: (text, record) => record.role.map((r) => <Tag key={r}>{r}</Tag>),
    },
    {
      title: 'Action',
      fixed: 'right',
      width: 70,
      render: (text, record) => (
        <div style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center', fontSize: '15px' }}>
          <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }}>
            <Link to={editPath + record._id}>
              <Edit title={`Edit ${title.toLowerCase()}`} style={{ textDecoration: 'none', color: '#90989E' }} />
            </Link>
          </div>
          <div style={{ margin: '2px', padding: '2px', cursor: 'pointer' }}>
            <Popconfirm
              disabled={record._id === user.data.id}
              placement='left'
              title={`This will delete ${title.toLowerCase()}`}
              onConfirm={() => {
                deleteHandler(record._id);
              }}
              okText='Ok'
              cancelText='Cancel'
            >
              <Trash title={`Delete ${title.toLowerCase()}`} style={{ color: '#90989E' }} />
            </Popconfirm>
          </div>
        </div>
      ),
    },
  ];

  const isDataFetched = !user.isLoading && user.data && currentuser.language;

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  console.log(data);

  return (
    <>
      <div className='table-manage'>
        <Table
          bordered
          dataSource={data}
          columns={columns}
          rowKey='_id'
          pagination={{
            defaultPageSize: 20,
            position: 'bottom',
            showSizeChanger: false,
            pageSizeOptions: ['10', '20', '50', '100'],
            hideOnSinglePage: true,
          }}
        />
      </div>
      <Modal open={isModalVisible} onCancel={handleCancel} footer={null} centered={true}>
        <div style={{ padding: '8px' }}>
          <div>
            {id && !usersData.isError && usersData.data && isDataFetched && (
              <UserForm
                isNew={false}
                data={usersData.data}
                language={currentuser.language}
                updateHandler={updateUser}
                SERVER_URL={SERVER_URL}
                token={currentuser.data.token}
                teams={teams}
                branchs={branchs}
              />
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AdminsTable;

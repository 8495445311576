import React, { useEffect } from 'react';
import HomebaseStatsEntry from './HomebaseStatsEntry';
import { HOMEBASE_STATS } from '../../config';
import { LoadingOutlined } from '@ant-design/icons';

function HomebaseStatsList({ group, data, loading, setLoading, single }) {
  useEffect(() => {
    setLoading(true);
    data && Object.values(data).every((entry) => typeof entry !== 'undefined') && setLoading(false);
  }, [data, setLoading, group]);

  return (
    <div className={single ? 'homebase__list homebase__list--single' : 'homebase__list'}>
      {loading ? (
        <LoadingOutlined spin style={{ color: '#4962E2' }} />
      ) : (
        Object.entries(HOMEBASE_STATS)
          .filter(([name, entry]) => name !== 'noicon')
          .filter(([name, entry]) => entry.group === group)
          .map(([name, entry]) => {
            return (
              data &&
              (data[name] || data[name] === 0) &&
              data[name] !== undefined && <HomebaseStatsEntry entry={entry} name={name} data={data[name]} />
            );
          })
      )}
    </div>
  );
}

export default HomebaseStatsList;

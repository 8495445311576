import React, { useEffect } from 'react';
import { Input, Button, Form } from 'antd';

const formInit = {
  _id: null,
};

const ClientBranchForm = ({ isNew, isMaster, onSubmit, branch, parent, setEdit, level }) => {
  const [form] = Form.useForm();
  let initialValues = isNew ? { ...formInit } : { ...formInit, ...branch };
  useEffect(() => form.resetFields());
  const onFinish = async (values) => {
    setEdit({ visible: false });
    if (isMaster) onSubmit({ branchName: values.branchName, level: 1, parentBranch: null }, isNew);
    else if (isNew) onSubmit({ ...values, parentBranch: parent, level }, isNew);
    else onSubmit({ ...values, id: branch._id }, isNew);
  };

  return (
    <div className='client-branch-modal'>
      <div className='panel-heading'>
        <h4 className='panel-title'>
          <span>{isNew ? 'Add' : 'Edit'} Site</span>
        </h4>
      </div>
      <Form
        className='form-horizontal'
        initialValues={initialValues}
        onFinish={(values) => onFinish(values)}
        layout='vertical'
        form={form}
      >
        <div>
          <Form.Item rules={[{ required: true, message: 'Please enter name!' }]} name='branchName'>
            <Input placeholder='Site Name' />
          </Form.Item>

          <Form.Item name='shortName'>
            <Input placeholder='Short Name' />
          </Form.Item>

          <Form.Item hidden name='level'>
            <Input placeholder='Level' />
          </Form.Item>
        </div>
        <div>
          <Button
            className='branch-form-button'
            type='primary'
            htmlType='submit'
            style={{ marginTop: '36px', width: !isNew ? '100%' : '165px' }}
          >
            {isNew ? 'Add' : 'Edit'} Site
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default ClientBranchForm;

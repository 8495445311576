import React, { useContext } from 'react';
import { UserContext } from '../../App';
import NavMenu from './NavMenu';
import Routes from '../routes/Routes';
import { Layout } from 'antd';

const { Content } = Layout;

const AdminLayout = () => {
  const user = useContext(UserContext);

  return (
    <Layout className='main-layout'>
      <NavMenu isCollapsed={user.isNavMenuCollapsed} setCollapsed={user.setIsNavMenuCollapsed} user={user} />
      <Layout>
        <Content className='main-content'>
          <Routes />
        </Content>
      </Layout>
    </Layout>
  );
};

export default AdminLayout;

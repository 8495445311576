import React from 'react';
import { ReactComponent as InfoIcon } from '../../icons/info-icon.svg';
import { Tooltip } from 'antd';

function HomebaseWidget({ title, children, tooltip, maxWidth = 'auto' }) {
  return (
    <div className='homebase__container' style={{ maxWidth: maxWidth }}>
      <h3 className='homebase__container-title'>
        {title}
        {tooltip && (
          <Tooltip color={'#4962E2'} placement='bottom' title={tooltip}>
            <InfoIcon className='homebase__list-entry-info homebase__widget-info' />
          </Tooltip>
        )}
      </h3>
      {children}
    </div>
  );
}

export default HomebaseWidget;

import React, { useState } from 'react';
import { Button, InputNumber, Input, Divider } from 'antd';
import StepNavigation from './StepNavigation';
import ClientBranches from './ClientBranches';

const SettingsStepOrganisation = ({
  step,
  setStep,
  completedStep,
  setCompletedStep,
  client,
  changeCompanyLevels,
  treeData,
  setTreeData,
  treeDataChangeHandler,
  treeBranchMoveHandler,
  treeDeleteDataHandler,
}) => {
  const [levelNumber, setLevelNumber] = useState(client?.organisationLevels ? client.organisationLevels : 1);
  const [level1, setLevel1] = useState(client?.level1 ? client.level1 : 'Company_Name');
  const [level2, setLevel2] = useState(client?.level2 ? client.level2 : 'Sites');
  const [level3, setLevel3] = useState(client?.level3 ? client.level3 : 'Country');

  return (
    <div className='company-settings-builder__step-1'>
      <div className='company-settings-builder__left-side'>
        <p className='company-settings-builder__intro-text'>
          This is your QClinical Admin Wizard. Here you can manage your Studies, Teams, Users, etc. This sequence will
          help you get started with your QClinical journey. You will be guided through this step sequence once but
          you’ll always be able to return and edit these settings.
        </p>
        <h2 className='company-settings-builder__step-subtitle'>
          <span>Step 1: </span>
          <span>Set up your Company Structure!</span>
        </h2>
        <StepNavigation step={step} setStep={setStep} completedStep={completedStep} />
        <p className='company-settings-builder__intro-text'>
          Customize your Company Structure below. Here you will determine the number of levels of your company and their
          names. The company tree on the right will reflect these changes and there you will add your branches.
        </p>
        {/* <div className='company-settings-builder__org-level-select'>
          <InputNumber min={1} max={3} value={levelNumber} onChange={(value) => setLevelNumber(value)} />
        </div> */}
        <div className='company-settings__level-fields'>
          <Input
            className='level1-input'
            value={level1}
            onChange={(e) => setLevel1(e.target.value)}
            placeholder='Level 1 (Company)'
          />
          {levelNumber > 1 && (
            <Input
              className='level2-input'
              value={level2}
              onChange={(e) => setLevel2(e.target.value)}
              placeholder='Level 2 (Country)'
            />
          )}
          {levelNumber > 2 && (
            <Input
              className='level3-input'
              value={level3}
              onChange={(e) => setLevel3(e.target.value)}
              placeholder='Level 3 (Site)'
            />
          )}
        </div>
        <div className='company-settings-builder__save-levels-button'>
          <Button
            type='primary'
            className='company-settings-builder__step-btn'
            onClick={() => changeCompanyLevels(levelNumber, level1, level2, level3, client._id)}
          >
            Save
          </Button>
        </div>
        <div className='company-settings-builder__bottom-row'>
          <Button disabled className='company-settings-builder__step-btn' style={{ zIndex: -200 }}>
            Back
          </Button>
          <Button
            id={'company-settings_next-step'}
            className='company-settings-builder__step-btn'
            type='primary'
            onClick={() => {
              if (!completedStep) setCompletedStep(1);
              setStep(2);
            }}
          >
            Next
          </Button>
        </div>
      </div>

      <Divider type='vertical' style={{ height: 'auto' }} />

      <div className='company-settings-builder__right-side'>
        <ClientBranches
          treeData={treeData}
          client={client}
          treeDataChangeHandler={treeDataChangeHandler}
          treeBranchMoveHandler={treeBranchMoveHandler}
          treeDeleteDataHandler={treeDeleteDataHandler}
          setTreeData={setTreeData}
        />
      </div>
    </div>
  );
};

export default SettingsStepOrganisation;

import React, { useEffect } from 'react';
import { Input, Button, Form, Select } from 'antd';

const { Option } = Select;

const formInit = {
  _id: null,
  name: '',
};

const TeamForm = ({ isNew, studies, data, onSubmit, createProcess = false }) => {
  const [form] = Form.useForm();
  let initialValues = { ...formInit, ...data };
  useEffect(() => form.resetFields());
  const onFinish = async (values, isNew) => onSubmit(values, isNew);

  return (
    <div className='panel'>
      <div className={!createProcess ? 'modal-team' : undefined}>
        <Form
          initialValues={initialValues}
          onFinish={(values) => onFinish(values, !data)}
          layout='vertical'
          form={form}
        >
          {!createProcess && (
            <div className='modal-team__header'>
              <h2>{isNew ? 'Add new' : 'Edit'} Team</h2>
            </div>
          )}
          <div className='modal-team__input' style={{ width: isNew ? '50%' : '100%' }}>
            <Form.Item rules={[{ required: true, message: 'Please enter team name!' }]} name='name'>
              <Input placeholder='Team Name' />
            </Form.Item>
            <Form.Item name='studies' rules={[{ required: true, message: 'Please select at least one study' }]}>
              <Select
                placeholder='Studies'
                mode='multiple'
                allowClear
                className='dropdown-small'
                showArrow
                maxTagCount={3}
              >
                {studies &&
                  studies.map((study) => (
                    <Option key={study._id} value={study._id}>
                      {study.name}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </div>
          <Button
            style={{ marginTop: '36px', width: !isNew ? '100%' : '165px' }}
            id={'company-settings_add-team'}
            type='primary'
            htmlType='submit'
          >
            {isNew ? 'Add' : 'Edit'} Team
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default TeamForm;

import React from 'react';
import { Button, Popconfirm, Table, Modal, Tag, Divider, Empty } from 'antd';
import StepNavigation from './StepNavigation';
import TeamForm from '../../components/forms/TeamForm';
import { ReactComponent as Trash } from '../../icons/ph_trash.svg';
import { ReactComponent as Edit } from '../../icons/ph_note-pencil-light.svg';

const SettingsStepTeams = ({
  step,
  setStep,
  completedStep,
  setCompletedStep,
  addAndEditTeamHandler,
  getTeamForEdit,
  teamForEdit,
  setTeamForEdit,
  deleteTeamHandler,
  teams,
  studies,
}) => {
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Studies',
      dataIndex: 'studies',
      render: (text, record) =>
        record.studies && record.studies.map((study) => <Tag key={study._id}>{study.name}</Tag>),
    },
    {
      title: 'Action',
      render: (text, record) => (
        <div style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center', fontSize: '15px' }}>
          <div style={{ margin: '2px', padding: '2px', cursor: 'pointer' }}>
            <Popconfirm
              placement='left'
              title={`This will delete team`}
              onConfirm={() => deleteTeamHandler(record._id)}
              okText='Ok'
              cancelText='Cancel'
            >
              <Trash title={`Delete team`} />
            </Popconfirm>
          </div>
          <div style={{ margin: '2px', padding: '2px', cursor: 'pointer' }}>
            <Edit
              onClick={() => getTeamForEdit(record._id)}
              title={`Edit team`}
              style={{ textDecoration: 'none', color: 'black' }}
            />
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className='company-settings-builder__step-1'>
      <div className='company-settings-builder__left-side'>
        <h2 className='company-settings-builder__step-subtitle'>
          <span>Step 3: </span>
          <span>Add Teams.</span>
        </h2>
        <StepNavigation step={step} setStep={setStep} completedStep={completedStep} />
        <p className='company-settings-builder__intro-text'>
          Now, you should add Teams and associate them to Studies that you have loaded into the system.
          <br />
          <br />
          Note that if you have already uploaded the template on the previous step, these should be automatically
          populated. You may still make changes on the right if needed. Otherwise, feel free to proceed to the next
          step.
        </p>
        <div className='company-settings-builder__forms'>
          {studies && studies.length > 0 && (
            <TeamForm isNew={true} studies={studies} onSubmit={addAndEditTeamHandler} createProcess={true} />
          )}
        </div>
        <div className='company-settings-builder__bottom-row'>
          <Button className='company-settings-builder__step-btn' onClick={() => setStep(2)}>
            Back
          </Button>
          <Button
            id={'company-settings_next-step'}
            className='company-settings-builder__step-btn'
            type='primary'
            disabled={teams.length === 0}
            onClick={() => {
              if (completedStep === 2) setCompletedStep(3);
              setStep(4);
            }}
          >
            Next
          </Button>
        </div>
      </div>

      <Divider type='vertical' style={{ height: 'auto' }} />

      <div className='table-manage'>
        <Table
          size='middle'
          bordered
          dataSource={teams}
          columns={columns}
          locale={{ emptyText: <Empty style={{ fontWeight: 600 }} description='No Teams, please add a team.' /> }}
          rowKey='_id'
          pagination={{
            defaultPageSize: 20,
            position: 'bottom',
            showSizeChanger: false,
            pageSizeOptions: ['10', '20', '50', '100'],
            hideOnSinglePage: true,
          }}
        />
      </div>

      {teamForEdit && studies && studies.length > 0 && (
        <div>
          <Modal open={teamForEdit} onCancel={() => setTeamForEdit(null)} footer={null} centered={true}>
            <div style={{ padding: '8px' }}>
              <div>
                <TeamForm isNew={false} data={teamForEdit} studies={studies} onSubmit={addAndEditTeamHandler} />
              </div>
            </div>
          </Modal>
        </div>
      )}
    </div>
  );
};

export default SettingsStepTeams;

import React, { useState, useEffect } from 'react';
import readXlsxFile from 'read-excel-file';
import { Button, Popconfirm, Table, Modal, Divider, Empty, Tabs, Tag, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import StepNavigation from './StepNavigation';
import PatientForm from '../../components/forms/PatientForm';
import { ReactComponent as Trash } from '../../icons/ph_trash.svg';
import { ReactComponent as Edit } from '../../icons/ph_note-pencil-light.svg';

const SettingsStepPatients = ({
  step,
  setStep,
  completedStep,
  branches,
  setCompletedStep,
  addAndEditPatientHandler,
  getPatientForEdit,
  patientForEdit,
  setPatientForEdit,
  deletePatientHandler,
  patients,
  studies,
  qoolections = [],
  bulkAddPatientsHandler,
  downloadPatientsTemplateHandler,
  client,
}) => {
  const [bulkUploadData, setBulkUploadData] = useState([]);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setBulkUploadData, bulkUploadData]);

  const uploadData = (file) => {
    let newBulkUploadData = [];
    return new Promise(async (resolve) => {
      await readXlsxFile(file, { sheet: 2 }).then(async (rows) => {
        // eslint-disable-next-line array-callback-return
        const promises = rows.slice(1).map((r, index) => {
          if (r[0] && r[1] && r[2]) {
            return newBulkUploadData.push({
              key: index,
              deviceId: (client?.companyPrefix + r[1]).toUpperCase(),
              study: r[2],
              site: r[3],
            });
          }
        });
        await Promise.all(promises);
      });
      console.log(newBulkUploadData);
      resolve(setBulkUploadData(newBulkUploadData));
    });
  };

  const columns = [
    {
      title: 'Device ID',
      dataIndex: 'deviceId',
    },
    {
      title: 'Site',
      dataIndex: 'site',
      render: (text, record) => (
        <Tag style={{ backgroundColor: '#E5f9c6' }} key={record?.site?._id + 110}>
          {record?.site?.branchName}
        </Tag>
      ),
    },
    {
      title: 'Study',
      dataIndex: 'study',
      render: (text, record) => (
        <Tag style={{ backgroundColor: '#C6e2de' }} key={record?.study?._id + 110}>
          {record?.study?.name}
        </Tag>
      ),
    },
    {
      title: 'Action',
      render: (text, record) => (
        <div style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center', fontSize: '15px' }}>
          <div style={{ margin: '2px', padding: '2px', cursor: 'pointer' }}>
            <Popconfirm
              placement='left'
              title={`This will delete patient`}
              patient
              onConfirm={() => deletePatientHandler(record._id)}
              okText='Ok'
              cancelText='Cancel'
            >
              <Trash title={`Delete patient`} />
            </Popconfirm>
          </div>
          <div style={{ margin: '2px', padding: '2px', cursor: 'pointer' }}>
            <Edit
              onClick={() => getPatientForEdit(record._id)}
              title={`Edit patient`}
              style={{ textDecoration: 'none', color: 'black' }}
            />
          </div>
        </div>
      ),
      fixed: 'right',
    },
  ];

  const columnsBulkUpload = [
    {
      title: 'User ID',
      dataIndex: 'deviceId',
    },
    {
      title: 'Site',
      dataIndex: 'site',
    },
    {
      title: 'Study',
      dataIndex: 'study',
    },
  ];

  return (
    <div className='company-settings-builder__step-1'>
      <div className='company-settings-builder__left-side'>
        <h2 className='company-settings-builder__step-subtitle'>
          <span>Step 5: </span>
          <span>Add Patients.</span>
        </h2>
        <StepNavigation step={step} setStep={setStep} completedStep={completedStep} />
        <p className='company-settings-builder__intro-text'>
          Add Patients to whom you will be sending published Qoolections. The table on the right will populate as you
          keep adding Patients. As with the previous steps, you can choose to add the Patients manually, or you can use
          our Bulk Upload function by downloading and completing our template.
        </p>
        {/* <div style={{ display: 'flex', flexDirection: 'row' }}>
          <h4>Patient licences:</h4>
          <h4 style={{ marginLeft: 10 }}>Total {client?.totalPatientLicences}</h4>
          <h4 style={{ marginLeft: 10 }}>Used {client?.licencedPatients?.length || 0}</h4>
        </div> */}
        <div className='company-settings-builder__forms'>
          <Tabs defaultActiveKey='1' centered className='company-settings__tabs'>
            <Tabs.TabPane tab='Manual Upload' key='1'>
              <PatientForm
                isNew={true}
                isWizard={true}
                onSubmit={addAndEditPatientHandler}
                branches={branches}
                studies={studies}
                qoolections={qoolections}
                createProcess={true}
                companyPrefix={client?.companyPrefix}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab='Bulk Upload' key='2'>
              <p className='company-settings-builder__intro-text'>
                You can download bulk upload template from{' '}
                <span style={{ color: 'blue', cursor: 'pointer' }} onClick={downloadPatientsTemplateHandler}>
                  here.
                </span>
              </p>
              <div className='bulk-upload-wrapper'>
                <Upload
                  className='bulk-upload-teams'
                  style={{ margin: 0 }}
                  action={uploadData}
                  accept='.xlsx'
                  name='excelData'
                >
                  <Button style={{ margin: 0 }} type='primary'>
                    {bulkUploadData && bulkUploadData.length === 0 ? 'Upload your completed template' : 'Re-upload'}
                  </Button>
                </Upload>
              </div>
              {/* <Table
                size='middle'
                bordered
                dataSource={bulkUploadData}
                columns={columnsBulkUpload}
                rowKey='key'
                pagination={{
                  defaultPageSize: 10,
                  position: 'bottom',
                  showSizeChanger: false,
                  pageSizeOptions: ['10', '20', '50', '100'],
                  hideOnSinglePage: false,
                }}
              /> */}
              {bulkUploadData && bulkUploadData.length > 0 && (
                <Button className='bulk-upload-save-button' onClick={() => bulkAddPatientsHandler(bulkUploadData)}>
                  Add/Update Patients
                </Button>
              )}
            </Tabs.TabPane>
          </Tabs>
        </div>
        <div className='company-settings-builder__bottom-row'>
          <Button className='company-settings-builder__step-btn' onClick={() => setStep(4)}>
            Back
          </Button>
          <Button
            id={'company-settings_next-step'}
            className='company-settings-builder__step-btn'
            type='primary'
            disabled={patients.length === 0}
            onClick={() => {
              if (completedStep >= 4) setCompletedStep(5);
              setStep(6);
            }}
          >
            Next
          </Button>
        </div>
      </div>

      <Divider type='vertical' style={{ height: 'auto' }} />

      <div className='table-manage'>
        <Table
          size='middle'
          bordered
          dataSource={patients}
          columns={columns}
          locale={{ emptyText: <Empty description='No Patients, please add patient.' /> }}
          rowKey='_id'
          pagination={{
            defaultPageSize: 10,
            position: 'bottom',
            showSizeChanger: false,
            pageSizeOptions: ['10', '20', '50', '100'],
            hideOnSinglePage: false,
          }}
        />
      </div>

      {patientForEdit && (
        <div>
          <Modal open={patientForEdit} onCancel={() => setPatientForEdit(null)} footer={null} centered={true}>
            <div style={{ padding: '8px' }}>
              <div>
                <PatientForm
                  isNew={false}
                  isWizard={true}
                  data={patientForEdit}
                  onSubmit={addAndEditPatientHandler}
                  studies={studies}
                  branches={branches}
                  qoolections={qoolections}
                  companyPrefix={client?.companyPrefix}
                />
              </div>
            </div>
          </Modal>
        </div>
      )}
    </div>
  );
};
export default SettingsStepPatients;

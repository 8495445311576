import React, { useContext, useEffect } from 'react';
import { UserContext } from '../../App';
import Axios from 'axios';
import { Link } from 'react-router-dom';
import HeaderComponent from '../../components/base/HeaderComponent';
import { useHistory } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { notification, Layout, Button, Input } from 'antd';
import useAxios from '../../hooks/useAxios';
import Table from '../../components/tables/Table';
import { ReactComponent as Magnifying } from '../../icons/magnifying-glas.svg';
import { SERVER_URL } from '../../config';

const TABLE_COLUMN_KEYS = [
  '_id',
  '__v',
  'createdAt',
  'updatedAt',
  'level1',
  'level2',
  'level3',
  'parentOfClient',
  'affiliateOfClient',
  'childOfClient',
  'totalCreatorLicences',
  'totalPublisherLicences',
  'totalMonitorLicences',
  'totalPatientLicences',
  'licencedCreators',
  'licencedPublishers',
  'licencedMonitors',
  'licencedPatients',
  'licencingPeriodStart',
  'licencingPeriodEnd',
  'licenceType',
  'organisationLevels',
  'taxId',
  'phone',
  'website',
];

const Clients = () => {
  const currentuser = useContext(UserContext);
  const [data, fetchData] = useAxios('', [], currentuser.data.token, 'get');
  const history = useHistory();

  useEffect(() => {
    fetchData(`${SERVER_URL}/clients`, []);
  }, [fetchData]);

  const deleteDataHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/clients/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: 'Item is deleted.',
        placement: 'bottomRight',
      });
      history.push('/');
      history.push('/clients');
    } catch (error) {
      notification.error({
        message: error?.response?.data?.message || 'Problem with delete. Please try later.',
        placement: 'bottomRight',
      });
    }
  };

  let columnKeys;
  if (data.data && data.data.items && data.data.items.length > 0) {
    const keys = Object.keys(data.data.items[0]);
    columnKeys = keys.filter((k) => !TABLE_COLUMN_KEYS.includes(k));
  }

  let tableData = [];
  if (data.data && data.data.items && data.data.items.length > 0) {
    tableData = data.data.items.map((item) => {
      item.createdAt = new Date(item.createdAt).toLocaleString();
      item.updatedAt = new Date(item.updatedAt).toLocaleString();
      return item;
    });
  }

  return (
    <Layout className='manage-layout'>
      <div
        style={{ textAlign: 'center', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}
      >
        <HeaderComponent text='Clients' />
        <div className='clients-content'>
          <div className='clients-container'>
            <div className='clients-upper-part'>
              <Input
                className='inppy'
                placeholder='Search'
                onChange={(e) => fetchData(`${SERVER_URL}/clients?search=${e.target.value}`, [])}
                prefix={<Magnifying style={{ marginRight: '5px' }} />}
              />
              <Link to='/new-client'>
                <Button className='butty' type='primary'>
                  Add new client
                </Button>
              </Link>
              <Button className='butty'>Bulk upload</Button>
            </div>

            <div className='clients-table' style={{ textAlign: 'center', backgroundColor: 'white' }}>
              {data.isLoading && <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />}
              {!data.isLoading && data.data && data.data.items && data.data.items.length > 0 && (
                <Table
                  data={tableData}
                  deleteHandler={deleteDataHandler}
                  columnKeys={columnKeys}
                  title='Clients'
                  editPath='/edit-client/'
                />
              )}
              {!data.isLoading && data.data && data.data.items && data.data.items.length === 0 && <h2>NO DATA</h2>}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Clients;

import React, { useContext, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Axios from 'axios';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import { UserContext } from '../../App';
import { Button, Layout, notification } from 'antd';
import Form from '../../components/forms/QooTypeForm';
import HeaderComponent from '../../components/base/HeaderComponent';

const EditQooType = (props) => {
  const currentuser = useContext(UserContext);
  const history = useHistory();
  const [data, fetchData] = useAxios('', {}, currentuser.data.token, 'get');
  const { id } = props.match.params;

  useEffect(() => {
    if (id) fetchData(`${SERVER_URL}/qoo-types/${id}`, {});
  }, [fetchData, id]);

  const onSubmit = async (formData, isNew) => {
    const method = isNew ? 'post' : 'put';
    const route = isNew ? `${SERVER_URL}/qoo-types` : `${SERVER_URL}/qoo-types/${id}`;
    try {
      await Axios[method](route, formData, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      notification.success({
        message: `Qoo type ${isNew ? 'created.' : 'updated.'}`,
        placement: 'bottomRight',
      });
      history.push('/qoo-types');
    } catch (error) {
      const msg = error.response ? error.response.data.message : error.message ? error.message : error;
      console.log(msg);
      notification.error({
        message: msg,
        placement: 'bottomRight',
      });
    }
  };

  const isDataFetched = !data.isLoading && data.data && currentuser.language;

  const [isNew, setIsNew] = useState(false);

  useEffect(() => {
    if ((data?.data && Object.keys(data?.data).length < 1) || !data?.data) {
      setIsNew(true);
    } else {
      setIsNew(false);
    }
  }, [data]);

  return (
    <Layout className='manage-layout'>
      <div
        style={{ textAlign: 'center', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}
      >
        <HeaderComponent text={isNew ? 'Add new Qoo Type' : 'Edit Qoo Type'} />
        <div className='form-wrapper'>
          {!id && isDataFetched && (
            <Form
              isNew={true}
              language={currentuser.language}
              onSubmit={onSubmit}
              SERVER_URL={SERVER_URL}
              token={currentuser.data.token}
            />
          )}
          {id && !data.isError && data.data && isDataFetched && (
            <Form
              isNew={false}
              data={data.data}
              language={currentuser.language}
              onSubmit={onSubmit}
              SERVER_URL={SERVER_URL}
              token={currentuser.data.token}
            />
          )}
        </div>
      </div>
    </Layout>
  );
};

export default EditQooType;
